@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('../src/css/font-awesome.min.css');

:root {
  --primary-btn-color: #24ECAD;
  --secondary-btn-color: #0099DE;
  --btn-hover-color: #24ECAD;

  --primary-color: #24ECAD;
  --secondary-color: #0099DE;

  --blue-color: #1755D4;
}

.font-1 {
  font-family: 'Montserrat', sans-serif;
}

.font-2 {
  font-family: 'Poppins', sans-serif;
}


/* Reset css --- */
* {
  padding: 0px;
  margin: 0px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

*:focus {
  outline: 0;
}

html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1em;
  color: #000;
  font-family: 'Poppins', sans-serif;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

:focus {
  outline: 0;
}

a,
a:link {
  text-decoration: none;
  outline: none;
}

p {
  padding: 2px 0;
  margin: 0;
}

.clear {
  clear: both;
}

a {
  color: #36A1D6;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #0c528f;
}

a:focus {
  outline: none !important;
}

:hover {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-family: 'Montserrat', Sans-serif;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
}

::-webkit-input-placeholder {
  opacity: 1;
  color: #999999;
}

:-moz-placeholder {
  opacity: 1;
  color: #999999;
}

::-moz-placeholder {
  opacity: 1;
  color: #999999;
}

:-ms-input-placeholder {
  opacity: 1;
  color: #999999;
}

/* --- Reset css */


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', Sans-serif;
  font-weight: 700;
}

p,
a,
li {
  font: 400 16px/1.19 'Montserrat', Sans-serif;
  color: #9e9e9e;
}



@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }

}

button {
  cursor: pointer !important;
}





a:hover {
  text-decoration: none !important;
}

a {
  text-decoration: none !important;
}


.bashboard-banner .banner-items-list {
  background: linear-gradient(0.25turn, #842DD1, #AB2DBA, #E22D9A);
  border-radius: 15px;
  padding: 5px;
}

.bashboard-banner .banner-items p {
  color: #fff !important;
  font-size: 14px;
}

.bashboard-banner .banner-items a {
  margin-top: 15px;
}

.bg2 {
  background: linear-gradient(0.25turn, #2C7180, #1EAB91, #14D29D) !important;
}

.bg3 {
  background: linear-gradient(0.25turn, #2370BE, #4BB1CE, #4BB4CF) !important;
}

.banner-content {
  padding: 136px 0px;
}

.banner-items {
  padding: 136px 0px;
}


.bashboard-banner {
  position: relative;
}

.bashboard-banner::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #15191E;
  top: 0;
  left: 0;
  opacity: 0.8;
}

.bashboard-banner {
  /*background-image: url('../images/Mask\ Group\ 3.png');*/
  background-size: cover;
  background-position: top center;
  width: 100%;

  background-repeat: no-repeat;

}

.banner-content .watch-demo-btn button {
  background-image: linear-gradient(to right, #00C2FF, #00E9D8, #00FFC2);
  padding: 15px 58px;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--poppins);
  cursor: pointer;
  overflow: hidden;
}

button:focus {
  box-shadow: none !important;
  outline: none !important;
}



.banner-content .watch-demo-btn button span {
  margin-right: 5px;

}


.banner-content h4 {
  font-size: 39px;
  font-family: var(--Montserrat);
  font-weight: 300;
}

.banner-content h1 {
  font-size: 73px;
  font-weight: 800;
  font-family: var(--Montserrat);
}

.banner-content h1 span {
  color: #00F5A0;
}

.banner-content p {
  width: 544px;
  font-family: var(--poppins);
  font-size: 18px;
  font-weight: 300;
}

.dashborad-sec-3 {
  position: relative;
  overflow: hidden;
  padding: 80px 0px;
  background-color: #15191E;
}

.banner-video-project {
  background-color: #15191E;
  padding: 40px 0px;
}

.banner-video-project .video-project h5 {
  font-size: 40px;
  font-family: var(--poppins);
}

.banner-video-project .video-project h5 span {
  color: #00F8B8;
}


.banner-video-project .video-project a span {
  color: #fff;
  margin-left: 20px;
}

.video-card {
  background-image: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
  border-radius: 10px;
  padding-top: 1px;
}


.project-create-btn button:hover {
  color: #e512c9 !important;
}


.ClipsReel-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .8s ease-in-out;
  background-color: #000000a3;
  border-radius: 10px;
  clip-path: circle(30.3% at 100% 0);
}

.ClipsReel-overlay ul {
  height: auto;
}

.ClipsReel-overlay ul li a span {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vidoe-play-icon span i {
  color: #fff !important;
}


.ClipsReel-overlay ul li:hover i {
  color: #e512c9 !important;
}

.ClipsReel-overlay ul li:hover a {
  color: #e512c9;
}

.ClipsReel-overlay ul li a {
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ClipsReel-overlay ul li a i {
  font-size: 18px;
  color: #000;
}


.video-img:hover .ClipsReel-overlay {
  opacity: 1;
  transition: .8s ease-in-out;
  clip-path: circle(141.4% at 100% 0);
}

.video-card .video-img {
  border-radius: 5px;
  position: relative;
  margin: 10px;
}

.video-card .video-title {
  padding: 10px;
  padding-top: 0px;
}

.video-card .video-title .vidoe-play-icon span {
  cursor: pointer;

}

.video-name {
  background-color: #fff;
  width: 100%;
  padding: 5px 10px;
  border-radius: 30px;
}

.video-name p {
  color: #000;
  font-family: var(--poppins);
  font-size: 14px;
  padding-left: 7px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
}

.vidoe-play-icon span img {
  width: 45px;
}

.banner-video-project .video-project a {
  color: #fff;
  font-size: 25px;
  font-family: var(--poppins);
  transition: .2s ease-in-out;
}

.banner-video-project .video-project a:hover {
  color: #e512c9;
  transition: .2s ease-in-out;
}

.banner-video-project .video-project a:hover span {
  color: #e512c9;
}





.dashborad-sec-3-content h4 {
  font-size: 30px;
  font-family: var(--poppins);
  transition: .2s ease-in-out;
}


.dashborad-sec-3-content h4:hover {
  color: #00F5A0;
  transition: .2s ease-in-out;
}

.dashborad-sec-3-content h6 {
  font-size: 18px;
  font-family: var(--poppins);
  color: #7BFFE9;
  text-transform: uppercase;

}

.dashborad-sec-3-content p {
  font-size: 18px;
  font-family: var(--poppins);
  font-weight: 200;
}

.imgsizeleft {
  margin-left: 50px;
}

.imgsizeright {
  margin-left: -320px;
}


.logoimg {
  /*background-image: url('../images/BG.png');*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
}



.login-box {
  padding: 0px 0px 30px;
  min-height: calc(100vh - 118px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-content h1 {
  font-size: 55px;
  font-family: var(--Montserrat);
  font-weight: 800;
}

.login-content h1 span {
  color: #00F8B8;
}

.login-content p {
  width: 90%;
  font-size: 18px;
  font-family: var(--poppins);
  font-weight: 300;
  margin: auto;
  color: #C1C1C1;
}

.login-content h5 {
  font-size: 29px;
  font-family: var(--poppins);
}



.login-form {
  padding: 50px;
  background-color: #101010;
  border-radius: 25px;
  border: 1px solid #00E5FF !important;
}

.login-form h4 span {
  color: #00F8B8;
}

.login-form h4 {
  font-family: var(--Montserrat);
  font-size: 26px;

}

.login-form button {
  background-image: linear-gradient(to left, #00D3EB, #16B891);
  border: none;
  color: #fff;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 600;
  font-family: var(--poppins);
  cursor: pointer;
}

.login-form button:focus {
  color: #00b889 !important;
  background-color: #fff !important;
}

.login-form button:focus {
  background-image: none !important;
}

.login-form .forgot a {
  color: #C3C3C3 !important;
}

.login-form .forgot span:hover {
  color: #00F5A0;
  transition: .2s ease-in-out;
}

.login-form .form-group input {
  background-color: transparent !important;
  border-color: #34B8CC !important;
  border-radius: 10px !important;
}

.login-form .form-group input:focus {
  box-shadow: none !important;
}

.login-form .form-group .form-control {
  color: #fff !important;
}


.login-form a span {
  color: #5EACB1;
  margin-left: 5px;
  transition: .2s ease-in-out;
}

.footer .footer-menu ul li a {
  color: #fff;
  font-size: 16px;
  font-family: var(--poppins);
  transition: .2s ease-in-out;
}

.footer .footer-menu ul li a:hover {
  color: #00F5A0;
  transition: .2s ease-in-out;
}

.footer-copyright p a:hover {
  color: #00F5A0;
  transition: .2s ease-in-out;
}

.footer .footer-menu ul li+li {
  margin-left: 40px;
}

.footer .footer-copyright p {
  font-size: 16px;
  font-family: var(--poppins);
}

.footer .footer-copyright p a {
  transition: .2s ease-in-out;
}

.footer {
  background-color: #0000007a;
  padding: 10px 0px;
}



.footerbg {
  background-color: #000000;
  padding: 15px 0px;
}




.banner-items .banner-items-list .col-md-3 img {
  transform: scale(1);
  transition: .4s ease-in-out;
}

/* .banner-items .banner-items-list{
    transform: scale(1);
    transition: .4s ease-in-out;
}

 .banner-items .banner-items-list:hover{
    transform: scale(1.05);
    transition: .4s ease-in-out;
}  */

.banner-items .banner-items-list:hover .col-md-3 img {
  transform: scale(0.9);
  transition: .4s ease-in-out;
}



.project-banner {
  padding: 20px 0px;
  background-color: #15191E;
}


.project-header-name .project-conut h6 {
  color: #A2A2A2;
  font-size: 25px;
  font-family: var(--poppins);
  margin-left: 30px !important;
  position: relative;
  font-weight: 200;
}

.project-header-name .project-conut h6::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 20px;
  background-color: #A2A2A2;
  top: 3px;
  left: -15px;
}



.project-header-name .project-item {
  font-size: 25px;
  font-family: var(--poppins);
  font-weight: 600;
}



.video-img img {
  width: 100%;
  height: 192px;
}


.project-button .project-create-btn button {
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  background-image: linear-gradient(to right, #36D6FF, #C867FF);
  border: none;
  font-size: 16px;
  font-family: var(--poppins);
  font-weight: 600;
  cursor: pointer;
}

.project-button .project-create-btn button:focus {
  background-color: #fff !important;
  background-image: none !important;
  color: #2fcac9 !important
}

.project-input-box .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background: #fff;
  border: none !important;
  color: #979797;
  padding-top: 10px;
  font-size: 14px;
}

.project-input-box .input-group .input-group-prepen {
  margin-right: 0px !important;
}

.project-input-box .input-group input {
  border: none !important;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 44px;
}

.project-input-box .input-group input:focus {
  box-shadow: none !important;
}

.project-input-box ::placeholder {
  color: #7A7A7A !important;
  font-size: 14px;
  font-family: var(--poppins);
}

.project-input-box {
  margin-right: 20px;
}

.project-banner .bottom-line {
  border-bottom: 1px solid #A2A2A2;
  padding-bottom: 20px;
  display: block;
  width: 98%;
  margin: auto;
}

.project-banner {
  min-height: 100vh;
  padding-bottom: 40px;
  padding-top: 90px;
}



.login-form .form-group label {
  font-size: 20px;
  font-family: var(--poppins);
  color: #C3C3C3;
  margin-left: 10px;
}

.login-form .form-group input {
  height: 50px;
}

.login-form ::placeholder {
  font-size: 16px;
  color: #707070;
  font-family: var(--poppins);
  padding-left: 10px;
}

.btn-change6 {

  box-shadow: 0 0 1px #ccc;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  box-shadow: 0 0 0 #fff inset;
  cursor: pointer;

}


.project-input-box input::placeholder {
  font-size: 16px !important;
  color: #000 !important;
  font-weight: 500px;

}



.login-form button:hover {
  color: #00b889 !important;
}


.btn-change6:hover {
  box-shadow: 1000px 0 0 #fff inset;
  transition: .3s ease-in-out;
  cursor: pointer;
}


.medium-btn {

  box-shadow: 0 0 1px #ccc;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  box-shadow: 0 0 0 #fff inset;
  cursor: pointer;
}


.medium-btn:hover {
  box-shadow: 300px 0 0 #fff inset;
  transition: .3s ease-in-out;
  cursor: pointer;
}


.btn-change7 {

  box-shadow: 0 0 1px #ccc;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  box-shadow: 0 0 0 #fff inset;
  cursor: pointer;
}

.btn-change7:hover {
  box-shadow: 400px 0 0 #fff inset;
  transition: .3s ease-in-out;
  cursor: pointer;
}

.toggleupdate {
  background: linear-gradient(to left, var(--primary-btn-color), var(--secondary-btn-color));
  border: none;
  padding: 7px 30px;
  border-radius: 30px;
  color: #fff;
  height: 45px;
}

.toggleupdate:hover {
  color: var(--btn-hover-color);
}

.cancl-btn a {
  color: #fff;
}

.btn-cancl {
  position: relative;
  z-index: 9999;
  display: inline-block;
}

.cancl-btn {
  background: linear-gradient(to left, var(--primary-btn-color), var(--secondary-btn-color));
  border: none;
  padding: 13px 30px;
  border-radius: 30px;
  height: 45px;
  color: #fff;
  overflow: hidden;
  z-index: 2;
  position: relative;
}

.cancl-btn:hover {
  color: var(--btn-hover-color) !important;
  background-color: #fff;

}


.cancl-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 0%;
  height: 100%;
  z-index: -1;
  transition: .3s ease-in-out;
  border-radius: 30px;

}

.cancl-btn:hover::before {
  width: 100%;
  transition: .3s ease-in-out;
  color: #fff !important;
}


.fixed-me {
  /*background-image: url('../images/Navbg.png');*/
  background-size: cover;
  background-position: center;

  margin-bottom: 5px;
  position: fixed;
  top: 0;
  z-index: 8888;
  width: 100%;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
}






.watch-demo-btn button {
  position: relative;
  z-index: 999;
}

.watch-demo-btn button::before {
  content: '';
  width: 0%;
  height: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 30px;
  color: #00F5A0;
  transition: .3s ease-in-out;
  z-index: -1;
}

.watch-demo-btn button:hover::before {
  width: 100%;
  transition: .3s ease-in-out;
  border-radius: 30px;
}

.watch-demo-btn button:hover {
  color: #00b889 !important;
  transition: .4s ease-in-out;
}

.select-box .form-group {
  width: 260px;
  border: 1px solid #00FFD4;
  border-radius: 5px;
  margin-right: 20px !important;
}

.select-box .form-group select {
  border: none !important;
}

.select-box .form-group select:focus {
  box-shadow: none !important;
}

.select-box .form-group select {
  background-color: #101010;
}

.search-select .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background: #fff;
  border: none !important;
  color: #979797;
  padding-top: 10px;
  font-size: 14px;
}

.search-select .input-group .input-group-prepen {
  margin-right: 0px !important;
}

.search-select .input-group input {
  border: none !important;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 44px;
}

.search-select .input-group input:focus {
  box-shadow: none !important;
}

.search-select ::placeholder {
  color: #8b7676 !important;
  font-size: 14px;
  font-family: var(--poppins);
}



.premium-header {
  background-color: #303030;
  padding: 15px 0px;
  margin-top: 65px;
}

.select-box select {
  color: #fff !important;
}


.template-name h4 {
  font-size: 22px;
  font-family: var(--poppins);
  font-weight: 400;
}



.template-number h6 {
  color: #A2A2A2;
  font-size: 22px;
  font-family: var(--poppins);
  margin-left: 30px;
  position: relative;
  font-weight: 200;
}


.template-number h6::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 18px;
  background-color: #A2A2A2;
  top: 4px;
  left: -15px;
}


.template-body {
  padding: 60px 0px;
  background-color: #171717;
  min-height: 100vh;
}

.template-names h6 {
  color: #DEDFDD;
}


/* .template-body .row {
    overflow-y: auto;
    height: 60vh;
} */




.template-body ::-webkit-scrollbar {
  width: 5px;
}

.template-body ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.template-body ::-webkit-scrollbar-thumb {
  background: #00F5A0;
  border-radius: 10px;
  height: 10vh !important;
}


.customModal .modal-dialog .modal-body {
  height: 60vh;
}

.customModal .modal-dialog .modal-footer button {
  background: linear-gradient(to left, #00D3EB, #16B891);
  padding: 7px 25px;
  border: none;
  color: #fff;
  border-radius: 5px;
}

.customModal .modal-dialog .modal-footer button:hover {
  color: #2fcac9;
}

.customModal .modal-dialog .modal-footer button:focus {
  background: #fff;
  color: #2fcac9;
}

.customModal .modal-dialog .modal-footer {
  border: none;
}

.customModal .modal-scrollbar {
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.modal-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.modal-scrollbar::-webkit-scrollbar-thumb {
  background: #00F5A0 !important;
  border-radius: 10px;
  height: 10vh !important;
}



/* .template-body ::-webkit-scrollbar-thumb:hover {
  background: #b30000;
} */



.modal-header .close {
  padding: 0px !important;
  margin: 0px !important;
}

.modal-header {
  width: 100%;
  margin: auto;
}

.modal-header .modal-title span {
  color: #00F5A0;
}

.modal-dialog {
  max-width: 1000px !important;
}




.modal-content {
  background-color: #323232;
  color: #fff !important;
}

.modal-title button span {
  color: #fff !important;
}

.facebook-item-box {
  border: 1px solid #707070;
  border-radius: 10px;
  background-color: #1C1C1C;
  transition: .4s ease-in-out;
  cursor: pointer;
}

.facebook-item-box:hover {
  border: 1px solid #00F5A0;
  transition: .4s ease-in-out;
  cursor: pointer;
}

.facebook-item-box h6 {
  color: #6A6A6A;
}

.facebook-item-box h5 {
  font-size: 14px;
}

.vidoe-play-icon {
  margin-top: 6px;
}



/* editor css start */


.editor-sec {
  background: #000000;
  min-height: 95vh;

  overflow: hidden;
}

.save-render-sec {
  background-color: #0c0c0c9e;
  padding: 10px;
}

/* .render-projectbtn {
    margin-right: 85px;
} */
.editor-part {
  overflow-y: auto;
  overflow-x: hidden;
  background: #191919;
  padding: 10px 0px;
  height: 650px;
}

.editor-part {
  overflow-y: auto;
  overflow-x: hidden;
  background: #191919;
  padding: 10px 0px;
  height: 650px;
}


.editor-part ::-moz-scrollbar {
  width: 5px !important;
  height: 10em !important;
}

.editor-part ::-moz-scrollbar-track {
  box-shadow: inset 0 0 10px grey !important;
  border-radius: 10px !important;

}

.editor-part ::-moz-scrollbar-thumb {
  background: #00F5A0 !important;
  border-radius: 10px !important;
  height: 10px !important;
}


.editor-part::-webkit-scrollbar {
  width: 5px !important;
  height: 10em !important;
}

.editor-part::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px grey !important;
  border-radius: 10px !important;

}

.editor-part::-webkit-scrollbar-thumb {
  background: #00F5A0 !important;
  border-radius: 10px !important;
  height: 10px !important;
}





.save-render-sec .add-campaign input {
  border-radius: 30px;
  border: none;
  background: #161616;
  width: 290px;
  height: 42px;
}

.save-render-sec ::placeholder {
  color: #A5A5A5;
}

.save-render-sec .add-campaign .form-control {
  color: #A5A5A5;
}

.save-render-sec .save-projectbtn button {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  padding: 7px 25px;
  border-radius: 5px;
  border: none;
  color: #fff;
}

.save-render-sec .render-projectbtn button {
  background: linear-gradient(to right, #A72CE3, #CB3B71);
  padding: 7px 25px;
  border-radius: 5px;
  border: none;
  color: #fff;
}

.editor-tab-menu .nav-item .nav-link h6 {
  color: #fff;
  margin-top: 5px;
  font-size: 12px;
  font-family: var(--poppins);
  margin-bottom: 0px;
}

.editor-tab-menu .nav-item {
  padding: 0px 10px;
}

.editor-tab-menu .nav-item .nav-link.active {
  background: #414141;
  border-radius: 10px;
  padding: 5px 10px;
}

.editor-tab-menu .nav-item .nav-link.active h6 {
  color: #00FFB1;
}

.editor-tab-menu .nav-item .nav-link {
  padding: 5px 10px;
}

.editor-tab-menu .nav-item .nav-link img {
  width: 17px;
}

.editor-tab-menu {
  padding: 10px;
}

.editor-preview {
  padding: 40px 0px;
  background: #333333;
  position: relative;
  /* width: 110%; */
}


.story-part-sec .story-para p {
  font-size: 12px;
  font-family: var(--poppins);
  color: #fff;
}

.story-part-sec .story-para {
  background-color: #242424;
  padding: 20px;
  border-radius: 30px;
  border: 1px solid #707070;
}

.editor-preview-box {
  width: 656px;
  height: 369px;
  overflow: hidden;
  margin: auto;
}

.para-active {
  background-color: #2DA882 !important;
}

.slide-box ul li {
  width: 150px;
  height: 70px;
  border: 1px solid #434343;
  background-color: #101010;
  margin: 0px 10px;
  text-align: center;
  display: flex;
  align-items: center;
  color: #9A9A9A;
  border-radius: 10px;
  font-size: 14px;
}


.slide-sec {
  background-color: #0c0c0c;
  padding: 15px;
}

.slide-box .plus-icon i {
  color: #565656;
}

.start-end-time input {
  width: 100px;
  background: transparent;
  border: none;
  color: #fff !important;
}

.start-end-time input:focus-visible {
  outline: none !important;
}

.start-end-time input.form-control {
  color: #fff;
}

.start-end-time ::placeholder {
  color: #fff;
}


.controls-box {
  background: #000;
  padding: 20px;
  width: 657px;
  margin: auto;
  position: relative;
  z-index: 999;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 3px 5px -1px #000;
}



.controls-box .start-end-time ul li>input:focus {
  box-shadow: none !important;
}

.save-projectbtn button:hover {
  color: #2fcac9;
}

.save-projectbtn button:focus {
  background-color: #fff !important;
  background-image: unset !important;
  color: #2fcac9 !important;
}

.add-campaign input:focus {
  box-shadow: none;
}

.render-projectbtn button:hover {
  color: #2fcac9;
}

.render-projectbtn button:focus {
  background-color: #fff !important;
  background-image: unset !important;
  color: #2fcac9 !important;
}

.style-upload-part {
  background: #303030;
  /* padding: 10px; */
  border-radius: 10px;
}

.style-upload-box {
  background: #496BAE;
  height: 150px;
  border: 1px dashed #8fb5ff;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  color: #FFF;
}

.style-upload-box svg {
  font-size: 30px;
}

.style-upload-box .custom-file-input {
  position: absolute;
  top: 0;
  opacity: 0;
  background: red;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.style-upload-box h6 {
  font-size: 12px;
  margin-bottom: 0px;
  color: #FFF;
}

.style-upload-box .custom-file {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.position-text-box {
  background: #303030;
  padding: 10px 10px;
  border-radius: 10px;
}

.position-text-box .text-position-dropdown label {
  font-size: 14px;
  margin-left: 14px;
}

.position-text-box .text-position-dropdown .box:hover {
  background-color: #00BF85;
  transition: all .2s;
  border: 2px solid #00BF85;
}

.position-text-box .text-position-dropdown .box.box-active {
  background-color: #00BF85 !important;
  border: 2px solid #00BF85 !important;

}

.position-text-box .text-position-dropdown .box {
  margin: 5px;
  border: 2px solid #707070;
  border-radius: 0;
  background-color: #636363;
  cursor: pointer;
  padding: 10px;
  width: 20px;
  height: 20px;
  color: #fff;
  transition: all .2s;
}

.position-text-box .text-position-dropdown .box-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.remove-logo-switch-btn .form-check label {
  font-size: 14px;
  color: #8F8F8F;
}

.remove-logo-switch-btn .form-check .form-check-input {
  margin-top: 2px;
  margin-left: 10px;
  font-size: 24px;
}

.style-remove-cricle {
  width: 50px;
  height: 50px;
  background-color: #000;
  border-radius: 50%;
  border: 2px solid #686868;
}

.dimmer-wrapper {
  background-color: #303030;
  padding: 10px;
  border-radius: 10px;
}

.dimmer-wrapper .dimmer-light h6 {
  font-size: 14px;
}

.dimmer-switch-btn .remove-logo-switch-btn .form-check-input {
  margin-top: 2px;
  font-size: 24px;
}

.swap-template-wrapper {
  background-color: #303030;
  padding: 10px;
  border-radius: 10px;
}

.swap-template-wrapper .swap-template-box {
  background-color: #101010;
  height: 100px;
}

.swap-template-wrapper h6 {
  font-size: 14px;
}

.editor-tab-menu {
  /* width: 110%; */
  background: #232323;
}



/* media tab css start */




.media-menu-box .nav-item .nav-link {
  color: #fff;
  background-color: #3E3E3E;
  padding: 6px 20px;

  font-size: 13px;
  padding: 9px;
  font-family: var(--poppins);
  text-align: center;

}

.media-menu-box .nav-item .nav-link.active {
  background-color: #fff;
  color: #10CA9C;
  font-size: 13px;
  padding: 9px;
  font-family: var(--poppins);
  text-align: center;
}

.media-search-box .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #fff;
  border: 1px solid #fff;
  color: #979797;
  cursor: pointer;
}

.media-search-box .input-group input {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border: none;

}

.media-search-box .input-group-prepend {
  margin-left: 0px;
}

.media-search-box input:focus {
  box-shadow: none;
}

.search-laod {
  background-color: #101010;
  height: 100px;
}

.media-upload-box button {
  background: linear-gradient(to right, #B376FF, #4AC6FF);
  border: none;
  color: #fff;
  border-radius: 30px;
}

.media-upload-box {
  position: relative;
}

.media-upload-box .custom-file {
  position: absolute;
  top: 0px;
}

.media-upload-box .custom-file .custom-file-input {
  opacity: 0;
  background-color: red;
  border-radius: 30px;
  font-size: 2px;
  position: absolute;
  top: 0px;
  cursor: pointer;
}

/* media start css */


.credites-wrapper textarea {
  background-color: #0C0C0C;
  border: 1px solid #707070;
}

.credites-wrapper textarea:focus {
  background-color: #0C0C0C;
  box-shadow: none;
}

.credites-wrapper textarea.form-control {
  color: #787878;
}


.credites-wrapper label {
  font-family: var(--poppins);
}

.credites-upload-box .outer-images {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #000;
}

.credites-uplaod {
  position: relative;
  height: 100px;
  background-color: #171717;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #707070
}

.credites-uplaod h6 {
  font-size: 14px;
  color: #8F8F8F;
}

.credites-uplaod .custom-file {
  position: absolute;
  top: 0;
}


.credites-uplaod .custom-file-input {
  position: absolute;
  z-index: 2;
  width: 100%;
  margin: 0;
  opacity: 0;
  background: red;
  height: 261%;
  font-size: 1px;
  cursor: pointer;
}

.credites-upload-box {
  padding: 20px;
  background: #303030;
  border-radius: 10px;
}

.remove-outer-logo .form-check-input {
  margin-left: -5px;
  font-size: 24px;
  margin-top: 0px;
}

.outro-template-box {
  padding: 20px;
  background: #303030;
  border-radius: 10px;
}

.outro-template-box h6 {
  font-family: var(--poppins);
}

.outer-images-box {
  background-color: #000000;
  height: 100px;
}


/* audio editor css start */



.audio-box-sec .audio-box-menu .nav-item .nav-link.active {
  background-color: #fff;
  color: #10CA9C;
  text-align: center;
  padding: 10px;
  font-size: 13px;
  font-family: var(--poppins);
}

.audio-box-sec .audio-box-menu .nav-item .nav-link {
  background-color: #3E3E3E;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
  font-family: var(--poppins);
  padding: 10px;
  text-align: center;
}

.horigental-line {
  border: 1px solid #BEBEBE;
  width: 100%;
  display: block;
}

.audio-music-box .card {
  background: linear-gradient(to bottom, #00EDBC, #009DB0);
  border-radius: 10px;
  transition: .4s ease-in-out;
}

.audio-music-box .card:hover {
  transform: scale(1.05);
  transition: .4s ease-in-out;
}

.audio-music-box .card .card-footer {
  background-color: #54D1CE;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}


.audio-music-box .card .card-title {
  padding: 10px;
  font-size: 14px;
}

.audio-music-box .card-body {
  padding: 0px;
}

.card-music-name h6 {
  font-size: 14px;
}

.audio-slide-box .form-group select {
  background-color: #0C0C0C;
  color: #fff;
  box-shadow: none;
  border-color: #707070;
  font-size: 14px;
}

.audio-language-box .form-group select {
  background-color: #0C0C0C;
  color: #fff;
  box-shadow: none;
  border-color: #707070;
  font-size: 14px;
}

.audio-language-box .form-group label {
  font-size: 12px;
  font-family: var(--poppins);
}

.audio-voice-box .form-group label {
  font-size: 12px;
  font-family: var(--poppins);
}

.audio-slide-box .form-group label {
  font-size: 12px;
  font-family: var(--poppins);
}

.audio-language-box .form-group .form-select {
  padding-right: 0px;
}

.audio-voice-box .form-group select {
  background-color: #0C0C0C;
  color: #fff;
  box-shadow: none;
  border-color: #707070;
  font-size: 14px;
}

.audio-voice-box .form-group .form-select {
  padding-right: 0px;
}

.audio-add-text-box textarea {
  background: #0C0C0C;
  border-color: #707070;

}

.audio-add-text-box input {
  background: #0C0C0C !important;
  border-color: #707070 !important;

}

.audio-add-text-box textarea:focus {
  background: #0C0C0C;
  box-shadow: none;
}

.audio-add-text-box textarea.form-control {
  color: #787878;
}

.tts-box-wrapper {
  background: #303030;
  border-radius: 10px;
  padding: 10px;
}

.audio-preview-btn button {
  background: linear-gradient(to right, #4AC6FF, #B376FF);
  border: none;
  color: #fff;
  border-radius: 50px;
  height: 45px;
}


.audio-preview-btn button:hover {
  color: #2fcac9;
}

.audio-save-use-btn button:hover {
  color: #2fcac9;
}

.audio-save-use-btn button {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  border: none;
  color: #fff;
  border-radius: 50px;
  height: 45px;
}

.audio-library-boxs .audio-upload-btn button {
  border-radius: 30px;
  background: linear-gradient(to right, #4AC6FF, #B376FF);
  border: none;
  height: 45px;
  color: #fff;
}

.audio-upload-btn {
  position: relative;
}






.audio-library-boxs .audio-upload-btn .custom-file-input {
  position: absolute;
  z-index: 2;
  width: 100%;
  margin: 0;
  opacity: 0;
  background: red;
  border-radius: 30px;
  font-size: 1px;
  height: 45px;
  cursor: pointer;
}

.audio-library-boxs .audio-upload-btn .custom-file {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
  top: 0;
}


.upload-box-card .card {
  background: linear-gradient(to bottom, #B94EFF, #DB438F);
  border: none;
  border-radius: 10px;
  transition: .4s ease-in-out;
}

.upload-box-card .card:hover {
  transform: scale(1.05);
  transition: .4s ease-in-out;
}

.upload-box-card .card-footer {
  background: #B240E6;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.upload-box-card .card-title {
  padding: 10px;
  font-size: 14px;
}

.upload-box-card .card-body {
  padding: 0px;
}

.card-play-icon {
  cursor: pointer;
}


.remove-logo-switch-btn .form-check-input:checked {
  background: #C23636;
  box-shadow: none;
  border-color: #C23636;
}

.remove-logo-switch-btn .form-switch .form-check-input:checked {
  background-position: right center !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-repeat: no-repeat !important;
  box-shadow: none;
}


.remove-logo-switch-btn .form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  box-shadow: none;
}



.dimmer-switch-btn .remove-logo-switch-btn .form-check-input:checked {
  background: #00BF85;
  box-shadow: none;
  border-color: #00BF85;
}


.dimmer-switch-btn .remove-logo-switch-btn .form-switch .form-check-input:checked {
  background-position: right center !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-repeat: no-repeat !important;
  box-shadow: none;
}


.dimmer-switch-btn .remove-logo-switch-btn .form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  box-shadow: none;
}


header .editormenu {
  left: -182px !important;
}

.slide-box {
  overflow-x: auto;
  overflow-y: hidden;
}

.text-font-size {
  width: 120px;
}

.text-box-sec .text-box-wrapper select {
  background-color: #0C0C0C;
  color: #fff;
}

.text-box-sec .text-box-wrapper .text-font-style select {
  border-color: #00F5A0;
}


.font-style-boxs .btn-group button {
  background-color: #0C0C0C;
  margin: 0px 10px;
  border-radius: 5px !important;
}



.profile-sec {
  background-color: #171717;
  padding: 120px 0px 60px;
  min-height: 100vh;
}

.user-img {
  position: relative;
  width: 100px;
  height: 100px;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  overflow: hidden;
}

.user-img .custom-file {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 65px);
  margin-bottom: 0;
  top: 0;
  left: 0;
  background: red;
  font-size: 1px;
  border-radius: 50%;
  opacity: 0;
}


.user-img .custom-file-input {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  background: orange;
  border-radius: 100%;
  cursor: pointer;
}

.user-details h5 {
  font-size: 24px;
  font-family: var(--poppins);
  color: #fff;
}

.user-details button {
  padding: 10px 30px;
  border: none;
  background: linear-gradient(to right, #16B891, #00D3EB);
  border-radius: 30px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--poppins);
}

.user-details .user-img span {
  color: #14BB9B;
}


.form-box .form-group input {
  background-color: transparent !important;
  border: none;
  padding: 0px;
}

.form-box .form-group input:focus {
  box-shadow: none !important;
}

.form-box .form-group {
  background-color: #1E1E1E;
  color: #fff;
  padding: 5px 20px;
  border-radius: 10px;
  border: 1px solid #707070;
}

.formactive {
  border: 1px solid #00F8B8 !important;
}

.form-box .form-group .form-control {
  color: #fff !important;
  font-weight: 200;
  font-size: 14px;
  font-family: var(--poppins);
}

.form-box .form-group ::placeholder {
  color: #6c757d !important;
}


.personal-details-box h6 {
  font-size: 20px;
  font-family: var(--poppins);
  color: #fff;
}

.form-box button {
  background: linear-gradient(to right, #36D6FF, #C867FF);
  border-radius: 10px;
  border: none;
  height: 45px;
  color: #fff;
}

.form-box button:hover {
  color: #2fcac9 !important
}

.form-box button:focus {
  background: #fff;
  color: #2fcac9;
}

.form-box {
  background-color: #000;
  padding: 50px;
  border-radius: 30px;
}


.gdpr-sec {
  padding: 80px 0px 80px;
  min-height: 100vh;
  background: #171717;
}

.gdpr-update-email {
  padding: 30px;
  background: #000000;
  border-radius: 10px;
}

.gdpr-sec .gdpr-update-email .form-group input {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.gdpr-update-email .form-group {
  background: #1E1E1E;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #1E1E1E;
}




.gdpr-update-email .form-group input:focus {
  box-shadow: none !important;
}

.gdpr-update-email .form-group .form-control {
  background: none;
  color: #fff;
  font-size: 14px;
  font-weight: 200;
}

.gdpr-update-email .form-group ::placeholder {
  color: #c9c9c9;
}

.gdpr-update-btn button {
  background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
  border: none;
  color: #fff;
  border-radius: 30px;
  padding: 7px 30px;
}

.gdpr-update-btn button:hover {
  color: #e512c9;
}

.gdpr-update-btn button:focus {
  background: #fff;
  color: #e512c9;
}


.manage-data-box {
  background: #000000;
  padding: 40px 20px;
  border-radius: 10px;
}

.manage-data-box p {
  font-size: var(--poppins);
}

.manage-data-box p span {
  color: #f27277;
}

.manage-update-btn button {
  border: none;
  padding: 7px 20px;
  border-radius: 30px;
  color: #fff;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.manage-update-btn button::before {
  content: '';
  position: absolute;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 30px;
  z-index: -1;
  transition: .3s ease-in-out;
  overflow: hidden;
}

.manage-update-btn button:hover::before {
  width: 100%;
  transition: .3s ease-in-out;
  overflow: hidden;
}

.manage-update-btn button:hover {
  color: var(--btn-hover-color);
  position: relative;
  z-index: 999;
  overflow: hidden;
}



.add-account-sec {
  padding: 60px 0px;
  background: #171717;
  min-height: 100vh;
}

.add-account-box {
  background: #000000;
  padding: 40px;
  border-radius: 10px;
}

.add-account-box .form-group input {
  background: transparent;
  border: none;
  padding: 0px;
}

.text-muted {
  color: #ffffffbd !important;
}


.add-account-box .form-group input:focus {
  box-shadow: none;
}

.add-account-box .form-group ::placeholder {
  color: #6c757d;
  font-weight: 200;
}

.add-account-box .form-group .form-control {
  color: #fff;
  font-weight: 200;
}

.add-account-box .form-group select {
  background: transparent;
  border: none !important;
  padding: 0px;
  text-align: left;
  height: 24px !important;
  color: #6c757d;
}

.add-account-box .form-group select:focus {
  box-shadow: none;
}

.add-account-box .form-group {
  background: #1E1E1E;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #1E1E1E;
}

.add-account-box .form-group:focus-within {
  border-color: var(--secondary-btn-color) !important;
}

.add-account-save-btn button {
  background: linear-gradient(to right, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  border: none;
  padding: 7px 30px;
  color: #fff;
  font-family: var(--poppins);
  border-radius: 30px;
}


.add-account-save-btn button:hover {
  color: #5d00ff;
}

.add-account-save-btn button:focus {
  background: #fff;
  color: #5d00ff;
}

.team-member-box {
  background: #000000;
  padding: 40px;
  border-radius: 10px;
}

/* .text-wrapper{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;

} */


.help-support-banner {
  background-color: #000000;
  margin-top: 65px;
  overflow: hidden;
}


.customer-sec {
  padding: 60px 0px;
  background-color: #242424;
}

.customer-content h6 {
  color: #00FFBA;
}

.customer-content h5 {
  font-size: 28px;
  font-family: var(--poppins);
}

.customer-content a {
  padding: 10px 30px;
  background-color: var(--btn-hover-color);
  color: #fff;
  border-radius: 30px;
  margin-top: 10px;
  display: inline-block;
  font-weight: 600;
}

.customer-content a:hover {
  color: var(--btn-hover-color);
  font-weight: 600;
}

/* .gdpr-update-email .form-group:focus-within {
  border-color: var(--btn-hover-color) !important;
} */

.form-group:focus-within {
  border-color: var(--secondary-btn-color) !important;
}

.secarchicone-part #searchimg {
  position: relative;
  left: 40%;
}



.help-banner-content .input-group .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-left: 0px;
  background-color: #424a4bcf;
  border: none;
  color: #7A7A7A;
  font-size: 13px;
  padding-right: 0px;
  cursor: pointer;
}

.help-banner-content .input-group .input-group-prepend {
  margin-right: 1px;
}

.help-banner-content .input-group input {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  margin: 0px;
  background-color: #424a4bcf;
  border: none;
}

.help-banner-content .input-group input:focus {
  box-shadow: none;
}

.help-banner-content .input-group ::placeholder {
  color: #7A7A7A;
}

.help-banner-content .input-group .form-control {
  color: #fff;
}


.help-banner-content h3 {
  font-size: 42px;
  font-family: var(--poppins);
  font-weight: 600;
}

.help-banner-content .input-group.mt-4 {
  height: 50px;
}

.help-banner-content h3 span {
  color: #00F5A0;
}

.important-topics span {
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}


.important-topics span img {
  max-width: 50%;
}

.important-sec {
  background-color: #242424;
  padding: 60px 0px;
}


.important-topics {
  background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
  padding: 40px;
  border-radius: 15px;
  transition: .4s ease-in-out;
  height: 247px;
}

.important-topics:hover {
  transform: scale(1.02);
  transition: .4s ease-in-out;
}

.popular-see {
  background-color: #000000;
  padding: 60px 0px;
}

.popular-video-sec h3 {
  font-size: 26px;
  font-weight: 600;
  font-family: var(--poppins);
}

.important-sec h3 {
  font-size: 26px;
  font-weight: 600;
  font-family: var(--poppins);
}

.player-box {
  background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
  padding: 10px;
  border-radius: 10px;
}

.support-sec {
  padding: 60px 0px;
  background-color: #242424;
}

.support-content h6 {
  font-size: 25px;
  font-family: var(--poppins);
  color: #00F5A0;
}

.support-content h3 {
  font-size: 40px;
  font-family: var(--poppins);
}

.support-content p {
  font-size: 18px;
  width: 90%;
  font-family: var(--poppins);
}

.support-content button {
  background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
  color: #fff;
  padding: 7px 30px;
  border-radius: 30px;
  border: none;
}

.upgrade-menu .nav .nav-link:hover {
  background-color: #00BBAE;
}

.support-content button:hover {
  color: #2fcac9;
}

.support-content button:focus {
  background: #fff;
  color: #2fcac9;
}

.traning-sec {
  background-color: #232323;

  min-height: 100vh;
}

.popular-topics-content {
  background-color: #171717;
  padding: 20px 30px;
  min-height: 100vh;
}

.popular-topics-content .vidoebtn {
  background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
  padding: 10px 30px;
  border: none;
  border-radius: 30px;
  color: #fff;
}

/* .popular-topics-box .nav{
  background: #000000;
  border-radius: 10px;
  overflow: hidden;
  padding: 0px 0px 20px 0px;
} */

.popular-topics-box .nav .nav-link {
  color: #fff;
  padding-left: 40px;
}


.popular-topics-box .nav .nav-link.active {
  background-color: #00BBAE;
}



.integration-box .img-wrraper {
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.integration-box li {
  max-width: 120px;
  width: 100%;
  background-color: #000000;
  text-align: center;
  padding: 20px;
  margin: 0 12px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 40%), 0 0 20px 0 rgb(0 0 0 / 40%);
  display: block;
  color: #fff;
  cursor: pointer;
  transform: scale(1.05);
  transition: .4s all;
}

.integration-box li:hover {
  transform: scale(1);
  transition: .4s all;
}


.integration-box ul li a {
  color: #fff;
}


.integration-sec {
  background: #171717;
  padding: 120px 0px 60px;
}


.my-connection {
  background: #171717;
  padding: 60px 0px;
}

.connection-box {
  background: #edf3ff;
  padding: 20px;
  border-radius: 10px;
  scroll-margin: 80px 0 0 0;
}

.connection-box .text-white {
  color: #000 !important;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 15px;
}

.facebook-delete button {
  background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #fff;
}

.facebook-connection {
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
}

.facebook-connect-account button {
  background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #fff;
}

.facebook-connect-account button:hover {
  color: var(--btn-hover-color);
}

.facebook-connect-account button:focus {
  background: #fff;
  color: var(--btn-hover-color);
}

.facebook-delete button:hover {
  color: var(--btn-hover-color);
}

.facebook-delete button:focus {
  background: #fff;
  color: var(--btn-hover-color);
}

.youtube-connect button:hover {
  color: var(--btn-hover-color) !important;
}

.youtube-connect button:focus {
  background: #fff;
  color: var(--btn-hover-color);
}

.youbtube-connection .youtube-connect button {
  background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #fff;
}

.webinar-sec {
  padding: 60px 0px;
  /*background: url('../images/webnibeimg.png') 50%/cover no-repeat;*/


}

.webiner-content span {
  color: #fff;
}

.webiner-content h3 {
  font-size: 30px;
  font-family: var(--Montserrat);
  color: #fff;
}

.webiner-content h3 span {
  color: #00FBC5;
}


.webiner-content p {
  font-size: 18px;
  font-family: var(--poppins);
  color: #fff;
}

.webiner-content button {
  background: linear-gradient(to right, #00C2FF, #00FFC2);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #000;
  font-weight: 600;
}

.webiner-content button:hover {
  color: #00b889 !important;
}

.warraper {
  min-height: 100vh;
  background: #171717;
}


.upgrads-sec {
  padding: 120px 0px 60px;
  background: #171717;
}


.upgrade-menu {
  background: #000000;
  border-radius: 10px;
  overflow: hidden;
}

.upgrade-menu .nav .nav-link {
  color: #fff;
  padding: 10px 40px;

}

.upgrade-menu .nav .nav-link.active {
  background-color: #00BBAE;

}

.upgrade-content {
  /* background-color: #000000; */
  padding: 20px 30px;
  /* min-height: 100vh; */
}

.upgrade-box-content {
  background-color: #f5f5f5;
  border-radius: 10px;
  overflow: hidden;
}

.upgrade-heading h6 {
  background: linear-gradient(to right, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%) !important;
  padding: 10px;
  color: #FFF;
}

.upgrade-para {
  padding: 10px;
}

.upgrade-para p {
  color: #000 !important;
}

/* .upgrade-box-content button{
  background: linear-gradient(to right, #00D3EB , #16B891);
  padding: 7px 30px;
  border-radius: 30px;
  border:none;
  color:#fff;
} */


.upgrade-para button {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.upgrade-box-content button {
  background: linear-gradient(to right, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%) !important;
  border: none;
  border-radius: 30px;
  color: #fff;
  padding: 7px 30px;
}

.upgrade-box-content button:hover {
  color: #fff;
}

.btn-block {
  display: block !important;
  text-align: center !important;
}


/* .upgrade-para button:hover {
  color: #2fcac9;

}

.upgrade-para button:focus{
  background:#fff !important;
  color:#2fcac9 !important;
}


.upgrade-box-content button:focus{
  background:#fff !important;
  color:#2fcac9;
}

.tutorial-content .upgrade button{
  background: linear-gradient(to right, #00D3EB , #16B891);
  padding: 10px 30px;
  border:none;
  color:#fff;
  border-radius: 30px;

} */

.tutorial-content .upgrade button {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* width: 257px; */
}

/* .tutorial-content .upgrade button:hover{
  color:#2fcac9;
}

.tutorial-content .upgrade button:focus{
  background: #fff;
  color:#2fcac9;
} */


.modal .ecommercebox {
  max-width: 600px !important;
}


.ecommercebox .modal-body .form-group {
  width: 80%;
  margin: auto;
}

.ecommercebox .modal-body .modal-scrollbar {
  display: flex;
  align-items: center;
  justify-content: center;
}


.ecommercebox .modal-body .modal-scrollbar .form-group input {
  background: #1E1E1E;
  height: 50px;
}

.ecommercebox .modal-body .modal-scrollbar .form-group input:focus {
  border-color: #01D084;
  box-shadow: none;
}

.ecommercebox .modal-body .modal-scrollbar .form-group .form-control {
  color: #fff;
}

.ecommercebox .modal-body .modal-scrollbar {
  height: 40vh;
}



/* .ecommercebox .modal-body .modal-scrollbar .form-group button{
  background: linear-gradient(to right, #00D3EB , #16B891);
  border:none;
  padding: 10px 30px;
  border-radius: 30px;
  color:#fff;
}

.ecommercebox .modal-body .modal-scrollbar .form-group button:hover{
  color:#2fcac9;
}

.ecommercebox .modal-body .modal-scrollbar .form-group button:focus{
  background: #fff;
  color:#2fcac9;
} */

.hide-table-padding .form-group {
  background-color: #FFF;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
  color: #000 !important;
}

.hide-table-padding .form-group:focus-within {
  border-color: var(--secondary-btn-color);
}

.hide-table-padding .form-group label {
  font-size: 14px;
  color: #8b8b8b;
}

.brandonfocus:focus-within {
  border-color: var(--secondary-btn-color);
  box-shadow: none;
}

.hide-table-padding .form-group input {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.hide-table-padding .form-group input:focus {
  box-shadow: none;
}

.hide-table-padding .form-group .form-control {
  color: #000 !important;
}

.hide-table-padding ::placeholder {
  color: #6c757d;
}


.template-cover {
  background-image: linear-gradient(to bottom, #00FDC3, #2DA8C7);
  border-radius: 10px;
  padding-top: 1px;
}

.template-cover .template-img {
  border-radius: 10px;
  position: relative;
  margin: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
}

.template-time {
  position: absolute;
  background-color: #fff;
  padding: 1px 10px;
  color: #000;
  top: 5px;
  left: 5px;
  display: inline-block;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
}

input[type='checkbox'] {
  accent-color: var(--secondary-btn-color);
}

.template-names {
  padding: 0px 0px 5px;
}

.template-img:hover .ClipsReel-overlay {
  opacity: 1 !important;
  transition: .8s ease-in-out !important;
  clip-path: circle(70.7% at 50% 50%) !important;
}


/** Custom */
.my-account-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #43abc9;
}

.hide-social {
  display: none !important;
}


.sweet-alert {
  color: #0C0C0C !important;
}

.modal {
  z-index: 99999;
  background: rgba(0, 0, 0, 0.6);
}

.show-modal {
  display: block !important;
}

.model-close-button {
  background: none;
  border: none;
  margin-right: 7px;
  margin-top: 4px;
}


.credit-modal {
  scrollbar-width: normal;
  scrollbar-color: #777 #555;
  overflow-x: hidden;
  overflow-y: auto !important;
  max-height: calc(100vh - 200px);
}

.credit-modal::-webkit-scrollbar {
  width: 10px;
}

.credit-modal::-webkit-scrollbar-thumb {
  background-color: #e86be8;
  border-radius: 10px;
}

.credit-modal::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}

.credit-modal::-webkit-scrollbar-track {
  background-color: #5555;
}

.credit-modal::-webkit-scrollbar-track:hover {
  background-color: #555;
}


.load-icon-help {
  color: #8434db;
  font-size: 30px;
}

.webinar-sec {
  padding: 60px 0px;
  background: url('../src/images/webnibeimg.png') 50%/cover no-repeat;


}

.webiner-content span {
  color: #fff;
}

.webiner-content h3 {
  font-size: 30px;
  font-family: var(--Montserrat);
  color: #fff;
}

.webiner-content h3 span {
  color: #00FBC5;
}


.webiner-content p {
  font-size: 18px;
  font-family: var(--poppins);
  color: #fff;
}

.webiner-content button {
  background: linear-gradient(to right, #00C2FF, #00FFC2);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #000;
  font-weight: 600;
}

.webiner-content button:hover {
  color: #00b889 !important;
}

.accnt-mngmt-btn:hover {
  color: #00b889 !important;
}

.upload-btn-wrapper .custom-file-input {
  position: absolute;
  top: 0;
}


.script-sec {
  padding: 120px 0px 60px;
  background-color: #262626;
  min-height: 100vh;
}

.script-sec .form-group label {
  color: #fff;
}

.script-sec .form-group input {
  background-color: #0D0D0D;
  height: 49px;
}

.script-sec .form-group input:focus {
  border-color: #00FFB1;
  box-shadow: none;
}

.script-sec .form-group textarea {
  background-color: #0D0D0D;
  border-color: #707070;

}

.script-sec .form-group textarea:focus {
  border-color: #00FFB1;
  box-shadow: none;
}

.script-sec .form-group .form-control {
  color: #fff;
}

.script-create-btn button {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.script-create-btn a {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.script-create-btn a:hover {
  color: #2fcac9;
}


.script-create-btn button:hover {
  color: #2fcac9;
}


.secarchicone-part {
  position: relative;
  animation: mymove 3s;
  animation-iteration-count: 5;
}

@keyframes mymove {
  from {
    transform: scale(0.09);
  }

  to {
    transform: scale(1.1);

  }
}


.fb-card .youtubeicon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-60%, -30%);
  font-size: 30px;

}


.script-create-btn button:focus-within {
  color: #2fcac9 !important;
  background: #fff !important;
}

.active-dim {
  border: 1px solid #00f5a0;
}

.active-slide {
  border-color: #00f5a0 !important;
}

/* .modal-backdrop{
  position: unset !important;
} */

.cursor-pointer {
  cursor: pointer;
}

.hide-box {
  display: none !important;
}

.story-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.preview-text {
  position: absolute;
  bottom: 0;
  padding: 20px;
}

/*color picker*/
.swatch {
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
  display: inline-block;
  cursor: pointer;
  width: 100%;
  padding: 10px;
}

.swatch-color {
  width: 100%;
  height: 14px;
  border-radius: 2px;
}

.swatch-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.sketch-picker {
  position: absolute;
  z-index: 999;
}

.templateModel {
  margin: auto;
  max-width: 450px;
  height: auto;
}


.templateModel .modal-body {
  height: auto !important;
  box-shadow: 0 0 5px rgba(255, 255, 255, .1);
}

.btn-custom {
  background: linear-gradient(to right, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}

.btn-custom::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 0%;
  height: 100%;
  z-index: -1;
  transition: .3s ease-in-out;
  border-radius: 5px;
}

.btn-custom:hover::before {
  width: 100%;
}

.btn-custom:hover {
  color: var(--btn-hover-color) !important;
  transition: .3s ease-in-out;
}

.preview-text span div {
  display: inline;
}

.highlight-text {
  background-color: yellow;
}


.search-load-box ::-webkit-scrollbar {
  width: 5px;
}

.search-load-box ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.search-load-box ::-webkit-scrollbar-thumb {
  background: #00F5A0;
  border-radius: 10px;
  height: 10vh !important;
}

.scroll-bar ::-webkit-scrollbar {
  width: 5px;
}

.scroll-bar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.scroll-bar ::-webkit-scrollbar-thumb {
  background: #00F5A0;
  border-radius: 10px;
  height: 10vh !important;
}

/* Infinite scroll */
/* .infinite-scroll-component {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden !important;
} */

.infinite-scroll-component i {
  color: #CE88FF;
}

.slide-active {
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #e512c9;
}

.slide-active i {
  color: #e512c9;
  transform: scale(1.5);
}

.loader-center {
  position: absolute;
  top: 29%;
  left: 29%;
  color: #800080;
  font-size: 25px;
}

.delete-media {
  position: absolute;
  top: 6px;
  right: 5px;
  z-index: 1;
  color: #CE88FF;
  filter: drop-shadow(2px 4px 6px black);
  width: unset !important;
  height: unset !important;
}

.delete-media svg {
  position: absolute;
  right: 5px;
  top: 5px;
}

.delete-media i {
  color: #d20424;
}

.disable-click {
  pointer-events: none
}

.custom-anchor {
  text-decoration: underline !important;
  cursor: pointer;
}


.mediaList ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* margin-right: -5px;
  margin-left: -5px; */
}

.mediaList ul li {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 10px;
}

.audioList-bottom i {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 20px !important;
  width: 30px !important;
}

.audioList-top img {
  transition: all 0.5s ease;
}

.mediaList ul li:hover .audioList-top img {
  transform: scale(1.1);
}

.mediaList-single.m-1 {
  margin: 0 !important;
}


/* CSS Modified ================================================================================================ */

/* Common Pages */
body {
  background: #101216;
}

body.modal-open {
  padding: 0 !important;
}

.container {
  max-width: 1500px;
}

@media (max-width: 1699px) {
  .container {
    max-width: 1200px;
  }
}

/* Common Pages */

/* Login Page */
.loginHeader {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding-top: 40px;
}

.loginLogo {
  width: 280px;
}

.login-container {
  min-height: 100vh;
  background: url('../src/images/login-bg.jpg') top center no-repeat;
  background-size: cover;
}

.full-height-row {
  min-height: 100vh;
}

.full-height-container {
  min-height: 100vh;
}

.login-container-left h2 {
  font-size: 52px;
  color: var(--primary-color);
  font-weight: 700;
  text-align: center;
  /* text-shadow: 0 3px 6px #4a4a4a; */
}

.login-container-left h2 span {
  color: #FFF;
}

.login-container-left p {
  font-size: 15px;
  color: #FFF;
  text-align: center;
  padding-top: 10px;
}

.login-container-right {
  padding-left: 100px;
}

.login-container-right-main {
  background: linear-gradient(to bottom, #0084bf 0%, #004260 100%);
  border: 3px solid #FFF;
  border-radius: 30px;
  padding: 40px 60px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.57);
}

.login-container-right-main h2 {
  font-size: 23px;
  color: #FFF;
  font-weight: 500;
}

.login-container-right-main h2 span {
  color: var(--primary-color);
}

.login-container-right-main .form-group {
  margin-bottom: 0;
  margin-top: 25px;
}

.login-container-right-main .form-group label {
  display: block;
  color: #FFF;
}

.forgotLink {
  text-align: center;
  color: #fff;
  font-size: 14px;
}

.forgotLink a {
  color: var(--primary-color);
  font-size: 14px;
}

.forgotLink a:hover {
  color: var(--secondary-btn-color);
}

.login-container-right-main .form-control {
  background: #fff;
  border: 1px solid #34B8CC;
  color: #000;
  border-radius: 60px;
  padding: 12px 30px;
}

.login-container-right-main .form-control:focus {
  box-shadow: none;
  outline: 0;
  border: 1px solid #00F6FF;
}

.login-container-right-main input:-webkit-autofill,
.login-container-right-main input:-webkit-autofill:hover,
.login-container-right-main input:-webkit-autofill:focus,
.login-container-right-main input:-webkit-autofill:active {
  -webkit-text-fill-color: #000 !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  border: 1px solid #00F6FF;
}

.themeBtn {
  border: none !important;
  border-radius: 50px !important;
  padding: 13px 30px;
  background: #e512c9;
  background: -moz-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
  background: -webkit-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
  background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e512c9', endColorstr='#5d00ff', GradientType=1);
}

.themeBtn:focus {
  border: none !important;
  box-shadow: none !important;
  outline: 0 !important;
}

.errMessage {
  display: block;
  color: red;
  font-style: italic;
  font-size: 12px;
  padding-top: 5px;
}

@media (max-width: 1699px) {
  .login-container-right-main {
    padding: 40px;
  }
}

/* Login Page */

/* Header */
.appHeader {
  background: url('../src/images/header-bg.jpg') top center no-repeat;
  background-size: cover;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 8;
}

.appHeader-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.appHeader-right {
  display: flex;
  align-items: center;
}

.appHeader-main {
  padding: 15px 0;
}

.logo {
  width: 270px;
}

.appHeader-nav>ul>li {
  display: inline-block;
  margin-left: 60px;
}

.appHeader-nav>ul>li:first-child {
  margin-left: 0;
}

.appHeader-nav>ul>li>a {
  display: inline-block;
  color: #FFF;
  position: relative;
  padding: 5px 0;
  transition: all 0.5s ease;
  font-size: 16px;
}

.appHeader-nav>ul>li:hover>a {
  color: var(--primary-color);
}

.appHeader-nav>ul>li:hover>a::after {
  opacity: 1;
  visibility: visible;
}

.appHeader-nav>ul>li>a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: var(--primary-color);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.appHeader-nav>ul>li>a.active::after {
  opacity: 1;
  visibility: visible;
}

.siteWrap {
  padding-top: 80px;
  min-height: calc(100vh - 74px);
}

.siteWrap-in {
  padding: 80px 0;
  /* color: #FFF; */
}

.appHeader-action {
  display: flex;
  align-items: center;
}

.action-nav {
  margin-left: 30px;
}

.action-nav .dropdown {
  display: flex;
  align-items: center;
}

.action-nav button {
  background: none;
  margin: 0;
  padding: 0 8px 0 0;
  border: none;
  border-radius: 0;
  color: #FFF;
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
}

.action-nav button::after {
  position: absolute;
  right: 0;
  top: 22px;
  color: #FFF !important;
}

.action-nav button span {
  display: block;
  line-height: 16px;
}

.action-nav button span.welcometxt {
  color: var(--primary-color);
  font-size: 13px;
}

.action-nav button span.profileName {
  color: #FFF !important;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}

.action-nav button:focus {
  outline: none;
  box-shadow: none;
}

.action-nav .dropdown-menu {
  margin-top: 15px;
}

.dropProfileImage {
  width: 40px;
  margin-right: 8px;
}

.dropProfileImage img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.notification-nav {
  margin-left: 30px;
}

.notification-nav button {
  border: none;
  border-radius: 50px;
  color: #FFF !important;
  background: #e512c9;
  background: -moz-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
  background: -webkit-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
  background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e512c9', endColorstr='#5d00ff', GradientType=1);
}

.notification-nav button:focus {
  outline: 0;
  box-shadow: none !important;
}

.notification-nav button span {
  font-weight: 600;
}

.notification-nav button span+span {
  padding-left: 10px;
}

.notification-nav .dropdown-menu {
  margin-top: 21px;
  width: 300px;
}

.notification-single {
  color: #FFF;
  font-size: 12px;
  padding: 8px 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.notification-single:last-child {
  border-bottom: 0;
}

.notification-single:hover {
  background: #e512c9;
  background: -moz-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
  background: -webkit-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
  background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e512c9', endColorstr='#5d00ff', GradientType=1);
}

.dropdown-menu-dark {
  background: #FFF !important;
}

/* .dropdown-menu-dark .dropdown-item .fa{ margin-right: 8px;} */
.dropdown-menu-dark .dropdown-item {
  color: #000 !important;
  background: none;
  font-size: 15px;
  padding: 8px 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.dropdown-menu-dark .dropdown-item:last-child {
  border-bottom: 0;
}

.dropdown-menu-dark .dropdown-item:hover {
  background: var(--primary-btn-color);
  background: -moz-linear-gradient(left, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  background: -webkit-linear-gradient(left, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  background: linear-gradient(to right, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='var(--primary-btn-color)', endColorstr='var(--secondary-btn-color)', GradientType=1);
  color: #FFF !important;
}

/* Header */

/* Dashboard */
.hero-banner {
  height: calc(100vh - 180px);
  min-height: 500px;
  background: url('../src/images/hero-banner.jpg') top center no-repeat;
  background-size: cover;
  position: relative;
  color: #FFF;
}

.hero-banner-txt {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.hero-banner-txt .container,
.hero-banner-txt .row {
  height: 100%;
}

.hero-banner-right {
  padding-left: 100px;
}

.bannerLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000000;
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  height: 80px;
  color: #FFF;
  font-weight: 600;
  padding: 0 10px;
  padding-right: 20px;
  padding-left: 40px;
  text-transform: uppercase;
  margin: 30px 0;
}

.bannerLink-left img {
  width: 60px;
  margin-right: 12px;
}

.bannerLink:hover {
  color: #FFF;
  border: 1px solid var(--primary-btn-color);
  background: var(--primary-btn-color);
  background: -moz-linear-gradient(left, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  background: -webkit-linear-gradient(left, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  background: linear-gradient(to right, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='var(--primary-btn-color)', endColorstr='var(--secondary-btn-color)', GradientType=1);
}

.hero-banner-left h2 {
  font-size: 60px;
  font-weight: 800;
  -webkit-text-stroke: 1px #fff;
  background-color: hsla(0, 0%, 100%, 0);
  color: hsla(0, 0%, 100%, 0);
}

.hero-banner-left h3 {
  font-size: 60px;
  font-weight: 700;
}

.hero-banner-left p {
  padding-right: 105px;
  padding-top: 10px;
  font-size: 17px;
  line-height: 1.75;
}

.videoProject {
  padding: 30px 0;
  padding-top: 0;
  /* background: #FFF; */
}

.videoProject-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.videoProject-top h2 {
  font-family: 'Poppins', sans-serif;
  color: #3E3E3E;
  font-size: 28px;
  font-weight: 600;
}

.videoProject-top h2 span {
  color: var(--primary-color);
}

.proView {
  display: flex;
  align-items: center;
  color: #3E3E3E;
  font-size: 28px;
  font-weight: 600;
}

.proView span {
  display: inline-block;
  width: 35px;
  height: 35px;
  background: var(--blue-color);
  color: #FFF;
  border-radius: 100%;
  text-align: center;
  font-size: 23px;
  margin-left: 15px;
}

.proView:hover {
  color: var(--blue-color);
}

.videoProject-bottom {
  padding-top: 0px;
}

.videoProject-single {
  background: var(--blue-color);
  border-radius: 15px;
  padding: 10px;
  border: 1px solid var(--blue-color);
  margin-top: 30px;
}

.videoProject-single-top {
  height: 230px;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

.vidThumb {
  height: 230px;
  width: 100%;
  object-fit: cover;
}

.videoProject-single-bottom {
  display: flex;
  align-items: center;
  color: #FFF;
  padding: 8px 0;
  padding-top: 20px;
  justify-content: space-between;
}

.videoProject-single-bottom-desc {
  font-size: 13px;
  line-height: 18px;
  padding-left: 15px;
}

.videoProject-single-bottom-desc span {
  color: #fff;
}

.playBtn {
  width: 36px;
  height: 36px;
  background: #FFF;
  border-radius: 100%;
  text-align: center;
  color: #925FEB;
  line-height: 36px;
}

.playBtn .fa {
  display: inline-block;
  transform: translateX(2px);
}

.vidHover {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  padding: 0 30px;
  opacity: 0;
  visibility: hidden;
  flex-direction: column;
  transition: all 0.5s ease;
}

.vidHover-single {
  margin: 0 5px;
}

.vidHover-single span {
  display: block;
  text-align: center;
}

.vidHover-icon {
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 100%;
  background: #FFF;
  color: #000;
  font-size: 15px;
  margin: 0 auto;
}

.vidHover-single a {
  display: inline-block;
  text-align: center;
  color: #FFF;
}

.vidHover-single span+span {
  font-size: 13px;
  padding: 8px 0 0 0;
}

.vidHover-single a:hover .vidHover-icon {
  background: var(--primary-color);
}

.vidHover-single a:hover .vidHover-icon {
  color: #FFF;
}

.videoProject-single:hover .vidHover {
  opacity: 1;
  visibility: visible;
}

.videoProject-single-awating {
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-weight: 700;
  font-style: italic;
  background: #e512c9;
  background: -moz-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
  background: -webkit-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
  background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e512c9', endColorstr='#5d00ff', GradientType=1);
}

.vidiconAll {
  display: flex;
  align-items: center;
  padding-top: 25px;
}

.vidHover p {
  font-size: 14px;
  color: #fff;
}

.featureSec {
  padding-top: 80px;
}

.featureSec-single {
  color: #FFF;
  padding-bottom: 100px;
}

.featureSec-single:nth-child(even) [class*='col-'] {
  order: 2;
}

.featureSec-single:nth-child(even) [class*='col-']+[class*='col-'] {
  order: 1;
}

.featureSec-single:nth-child(even) .featureSec-left {
  padding-right: 0;
  padding-left: 100px;
}

.featureSec-left {
  padding-right: 100px;
}

.featureSec-single h6 {
  font-family: 'Poppins', sans-serif;
  color: #7B7B7B;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
}

.featureSec-single h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.featureSec-single p {
  padding-top: 20px;
}

.textL {
  position: relative;
  padding: 10px 0;
  padding-left: 15px;
  font-weight: 600;
  font-style: italic;
  margin-top: 20px;
  font-size: 15px;
  color: #000;
}

.textL::after {
  content: '';
  width: 4px;
  border-radius: 2px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: var(--primary-btn-color);
  background: -moz-linear-gradient(top, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  background: -webkit-linear-gradient(top, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  background: linear-gradient(to bottom, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='var(--primary-btn-color)', endColorstr='var(--secondary-btn-color)', GradientType=0);
}

.textL span {
  color: var(--blue-color);
}

.featureSec-right {
  text-align: right;
}

.featureSec-single:nth-child(even) .featureSec-right {
  text-align: left;
}

.lessonLink {
  background: url('../src/images/lesson-bg.jpg') top center no-repeat;
  background-size: cover;
  padding: 80px 0;
}

.demoLink {
  display: inline-block;
  color: #FFF;
  font-weight: 600;
  padding: 14px 30px;
  border-radius: 50px;
  margin-top: 30px;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
  border: 2px solid #FFF;
  background: linear-gradient(to right, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
}

.demoLink.alt,
.textarea-btn.alt {
  position: relative;
  z-index: 1;
  background: linear-gradient(to right, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
}

.demoLink.alt:hover,
.textarea-btn.alt:hover {
  color: var(--btn-hover-color) !important;
  transition: .3s ease-in-out;
}

.demoLink.alt::before,
.textarea-btn.alt::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 0%;
  height: 100%;
  z-index: -1;
  transition: .3s ease-in-out;
  border-radius: 5px;
}

.demoLink.alt:hover::before,
.textarea-btn.alt:hover:before {
  width: 100%;
  color: #fff !important;
  transition: .3s ease-in-out;
}

.demoLink-Ai-search {
  display: inline-block;
  min-width: 150px;
  color: #e512c9;
  border-radius: 5px;
  font-weight: 600;
  padding: 14px 30px;
  margin-top: 30px;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
  background: #fff;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e512c9', endColorstr='#5d00ff', GradientType=1);
}

.demoLink-topic {
  min-width: 315px;
  border-radius: 5px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 24px;
  padding-top: 14px;
  padding-bottom: 14px;
  cursor: pointer;
  border: none;
  color: #fff;
  transition: all 0.5s ease;
  background: var(--primary-btn-color);
  background: -moz-linear-gradient(left, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  background: -webkit-linear-gradient(left, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  background: linear-gradient(to right, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='var(--primary-btn-color)', endColorstr='var(--secondary-btn-color)', GradientType=1);
}

.create-or-line span {
  overflow: hidden;
  text-align: center;
}

.create-or-line span::before,
.create-or-line span::after {
  background-color: #fff;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 47.5%;
}

.create-or-line span::before {
  right: 0.5em;
  margin-left: -47.5%;
}

.create-or-line span::after {
  left: 0.5em;
  margin-right: -47.5%;
}

.demoLink:focus {
  border: none;
  box-shadow: none;
  outline: 0;
}

.demoLink .fa {
  margin-right: 10px;
}

.demoLink:hover {
  color: #FFF;
}

.lessonLink-right {
  color: #FFF;
  padding-left: 50px;
}

.lessonLink-right h6 {
  font-family: 'Poppins', sans-serif;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 22px;
}

.lessonLink-right h6 span {
  color: var(--primary-color);
}

.lessonLink-right h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  padding-top: 8px;
}

.lessonLink-right p {
  padding: 20px 0 0 0;
}

.btn.btn-primary {
  display: inline-block;
  position: relative;
  color: #FFF;
  font-weight: 600;
  padding: 14px 30px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
  background: linear-gradient(to right, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.btn.btn-primary:hover {
  color: var(--btn-hover-color) !important;
  transition: .3s ease-in-out;
}

.btn.btn-primary::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 0%;
  height: 100%;
  z-index: -1;
  transition: .3s ease-in-out;
  border-radius: 30px;
}

.btn.btn-primary:hover:before {
  width: 100%;
  color: #fff !important;
  transition: .3s ease-in-out;
}

.VideoModal .modal-dialog {
  max-width: 1000px;
}

.VideoModal .modal-content {
  background: #F2F3F7;
  border-radius: 15px;
}

.modalVidWrap {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.modalVid iframe {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.vidClose {
  position: absolute;
  right: 0;
  top: -40px;
  cursor: pointer;
}

.font-adjust {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.font-adjust>div {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.font-adjust>div input {
  background: #000;
  border: none;
  color: #FFF;
  padding: 10px !important;
  height: 36px;
}

.font-adjust>div input:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.font-adjust label {
  margin: 0 !important;
  padding: 0 8px 0 0 !important;
}

.font-adjust .picker {
  transform: none !important;
}

.research-btn {
  min-width: 190px;
  border-radius: 5px;
  padding-top: 14px;
  padding-bottom: 14px;
  display: inline-block;
  color: #FFF;
  font-weight: 400;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
  background: #e512c9;
  background: linear-gradient(to right, #AC46B4 0%, #4F94C9 100%);
}

.cont-research-btn .btn.btn-primary {
  padding-top: 10px;
  padding-bottom: 10px;
}

.back-Button a {
  background: #e512c9;
  border-radius: 50px !important;
  padding: 10px 30px !important;
  background: -moz-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
  background: -webkit-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
  background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e512c9', endColorstr='#5d00ff', GradientType=1);
}

.btn-wrapper .btn-2 {
  font: 700 16px/16px "Montserrat", sans-serif;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 var(--blue-color);
  font-size: 16px;
  font-weight: 700;
  color: var(--blue-color);
  padding: 15px 0;

}

.btn-wrapper .btn-2:hover,
.btn-3 {
  background-color: var(--blue-color);
  color: #fff;
}

.btn-3:hover {
  background-color: #fff;
  color: #8133db !important;
  box-shadow: 0 2px 4px 0 rgb(155 51 233 / 35%);
  border: 1.8px solid #8133db;
}



.delicon {
  position: absolute;
  right: 8px;
  top: 8px;
  opacity: 0;
  visibility: hidden;
}

.delicon svg {
  margin-left: 5px;
}

.editorReel-single:hover .delicon {
  opacity: 1;
  visibility: visible;
}

.proImage-holder .custom-file {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
}

.proImage-holder .custom-file input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.proImage-holder:hover .custom-file {
  opacity: 1;
  visibility: visible;
}

.custom-file svg {
  font-size: 22px;
}

.tableWrap .table-dark tr td {
  text-align: center;
}

.upgrade-para ol {
  padding-left: 15px;
}

.upgrade-para ol li {
  list-style-type: disc;
  margin-top: 8px;
}

/* Dashboard */

/* Profile */
.profileBlock {
  background: #F2F3F7;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  padding: 40px;
  margin-top: 45px;
}

.formSec .inpField {
  background: none;
  border: none;
  padding: 0;
}

.profileBlock h2 {
  font-size: 20px;
  font-weight: 500;
}

.profileBlock .table h6 {
  font-size: 15px;
  font-weight: normal;
}

.form-group-alt {
  position: relative;
  margin-top: 30px;
  margin-bottom: 0;
}

.form-group-alt .form-control {
  background: #FFF;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  height: 70px !important;
  color: #000 !important;
  padding: 15px 0 0 30px;
}

.form-group-alt select.form-control {
  padding-left: 24px;
}

.form-group-alt .form-control:focus {
  outline: 0 !important;
  box-shadow: none;
  border: 1px solid var(--secondary-btn-color);
}

.form-group-alt label {
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 12px;
  color: #848484;
  margin: 0;
  padding: 15px 0 0 30px;
  color: #000 !important;
}

.inpIcon {
  position: absolute;
  right: 15px;
  top: 50%;
  color: #606060;
  font-size: 24px;
  margin-top: -18px;
  cursor: pointer;
}

.proImage-wrap {
  text-align: center;
}

.proImage h5 {
  font-weight: 500;
  padding: 15px 0 0 0;
  font-size: 22px;
}

.proImage-holder {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border-radius: 100%;
  overflow: hidden;
}

.proImage-holder img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.btnUpload {
  position: relative;
  display: inline-block;
}

.btnUpload label {
  margin: 0;
  display: inline-block;
  color: #FFF;
  font-weight: 600;
  padding: 12px 40px;
  border-radius: 50px;
  margin-top: 15px;
  cursor: pointer;
  border: none;
  cursor: pointer;
  background: #e512c9;
  background: -moz-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
  background: -webkit-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
  background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e512c9', endColorstr='#5d00ff', GradientType=1);
}

.btnUpload input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.infoTitle {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 20px;
}

.profileBlock-wrap-last {
  margin-top: 50px;
}

.manage-block {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 15px;
  padding: 30px;
  margin-top: 20px;
}

.manage-block p {
  color: #000;
}

.manage-block:first-child {
  margin-top: 0;
}

.manage-block p {
  font-size: 15px;
}

.manage-block p span {
  color: #B48AFF;
}

.manage-block .btn {
  border-radius: 50px;
  color: #FFF;
  font-weight: 600;
  padding: 14px 30px;
}

.sweet-alert {
  color: #000;
}

.sweet-alert h2 {
  font-size: 20px !important;
}

.sweet-alert h2+div {
  font-size: 14px !important;
}

.sweet-alert p a {
  font-size: 14px !important;
}

.tableWrap {
  padding: 0;
  overflow: hidden;
}

.tableWrap .table-dark th {
  background: var(--blue-color) !important;
  font-weight: 500 !important;
  text-align: center !important;
}

.tableWrap .table-dark td {
  color: #000 !important;
  background: #FFF !important;
  box-shadow: none !important;
}

.tabLeft {
  min-height: calc(100vh - 145px);
}

.tabCont-left {
  overflow: hidden;
  border-radius: 10px;
  background: var(--blue-color);
}

.tabCont .nav-link {
  color: #FFF !important;
  font-size: 17px;
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 15px;
}

.tabCont .nav-link.active,
.tabCont .show>.nav-link {
  background: linear-gradient(to right, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%) !important;
}

.tabepaneIn-main {
  background: var(--blue-color);
  border-radius: 22px;
  border: 1px solid #707070;
  padding: 40px;
}

.tabepaneIn-cont {
  border-radius: 10px;
  background: #F2F3F7;
  padding: 30px;
  padding-top: 0;
  overflow: hidden;
}

.text-white {
  color: #000 !important;
}

.tabepaneIn-cont h3 {
  margin-left: -30px;
  margin-right: -30px;
  background: linear-gradient(to right, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  font-size: 18px;
  padding: 15px 0 15px 30px;
  margin-bottom: 30px;
  color: #FFF !important;
}

.tabepaneIn-cont h3 a {
  color: #FFF;
}

h2.tabTitle {
  font-size: 25px;
  padding-bottom: 20px;
  color: #000;
}

h2.tabTitle a {
  color: #FFF;
}

.tabepaneIn-cont-alt {
  padding-top: 40px;
}

.tabepaneIn.forTraining .tabepaneIn-main {
  margin-top: 30px;
}

.tabepaneIn.forTraining .tabepaneIn-main:first-child {
  margin-top: 0;
}

.tabLeft {
  background: #FFF;
  padding: 50px;
}

.tabRight {
  padding: 90px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.tabCont-right {
  color: #FFF;
}

/* Profile */

.help-banner {
  background: url('../src/images/help-banner.jpg') top center no-repeat;
  background-size: cover;
  height: 580px;
  color: #FFF;
}

.help-banner .container,
.help-banner .row {
  height: 100%;
}

.help-banner h2 {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

.help-banner h2 span {
  color: var(--primary-color);
}

.helpSearch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  background: #FFF;
  height: 60px;
  margin-top: 40px;
  overflow: hidden;
  padding: 0 4px;
  padding-left: 20px;
  border: 2px solid #fff;
}

.helpSearch-left {
  display: flex;
  align-items: center;
  width: calc(100% - 100px);
}

.helpSearch .demoLink {
  margin-top: 0;
  margin-right: -6px;
}

.helpSearch .fa {
  color: #7D7D7D;
  font-size: 30px;
}

.helpSearch input[type="text"] {
  width: calc(100% - 80px);
  padding: 16px 30px;
  border: none;
}

.impTopic {
  padding: 100px 0;
}

.impTopic h2 {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  padding-bottom: 30px;
}

.impTopic-single {
  background: #FFF;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--blue-color);
  border-radius: 10px;
  margin-top: 30px;
  padding: 0 15px;
}

.impTopic-single h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--blue-color) !important;
  padding-top: 10px;
}

.popularVideo {
  color: var(--blue-color);
  background: #FFF;
  padding: 100px 0;
}

.popularVideo h2 {
  display: inline-block;
  position: relative;
  border: 2px solid var(--blue-color);
  font-size: 30px;
  font-weight: 600;
  padding: 13px 40px;
  padding-left: 70px;
  margin-bottom: 30px;
}

.popularVideo h2::after {
  content: '';
  position: absolute;
  left: 30px;
  top: 50%;
  margin-top: -11px;
  width: 22px;
  height: 22px;
  background: var(--blue-color);
  border-radius: 100%;
}

.popularVideo-single {
  background: var(--blue-color);
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
  margin-top: 30px;
}

.popularVideo-vid {
  border-radius: 10px;
  overflow: hidden;
}

.popularVideo-vid img {
  width: 100%;
  height: 168px;
  object-fit: cover;
}

.popularVideo-txt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  font-size: 14px;
  color: #FFF;
}

.helpDesk {
  padding: 90px 0;
  color: #FFF;
  background: url('../src/images/lesson-bg.jpg') top center no-repeat;
  background-size: cover;
}

.helpDesk h6 {
  font-family: 'Poppins', sans-serif;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 22px;
}

.helpDesk h6 span {
  color: #D3A1FF;
}

.helpDesk h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  padding-top: 8px;
}

.helpDesk p {
  padding: 20px 0 0 0;
  color: #FFF;
}


.inteTop-main {
  padding: 80px 0;
  color: #FFF;
  border-bottom: 1px solid #7ba1f3;
}

.inteTop-main ul {
  text-align: center;
  padding-top: 50px;
}

.inteTop-main ul li {
  display: inline-block;
  margin: 10px 10px;
}

.inteTop-single {
  width: 150px;
  height: 150px;
  border-radius: 12px;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  color: #000;
}

.inteTop-single p {
  color: #000 !important;
  font-size: 14px;
  padding-top: 10px;
}

.inteTop-single img {
  max-height: 80px;
}

.inteTop-main ul li:hover .inteTop-single {
  transform: scale(1.05);
  background: linear-gradient(90deg, var(--primary-btn-color) 0, var(--secondary-btn-color));
  box-shadow: 0 0 20px 0 var(--primary-btn-color), 0 0 20px 0 var(--secondary-btn-color);
}

.inteTop-main ul li:hover .inteTop-single p {
  color: #FFF !important;
}

.inteTop-main ul li.active .inteTop-single img {
  filter: grayscale(100%);
}

h2.titleBdr {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  position: relative;
  padding: 0 0 20px 0;
  color: #FFF;
}

h2.titleBdr::after {
  content: '';
  position: absolute;
  width: 100px;
  height: 3px;
  background: var(--primary-color);
  left: 50%;
  margin-left: -50px;
  bottom: 0;
}

.connection {
  padding-top: 50px;
  padding-bottom: 80px;
}

.connection-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 140px;
  background: #000;
  border-radius: 10px;
  margin-top: 30px;
  color: #FFF;
  padding-left: 40px;
  padding-right: 40px;
}

.connection-single-left {
  display: flex;
  align-items: center;
}

.connection-single-left p {
  font-size: 15px;
  padding-left: 20px;
}

.connection-single.active .single-left-img img {
  filter: grayscale(100%);
}

.connectBt {
  color: #FFF;
  font-weight: 600;
  padding: 12px 40px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  margin-left: 10px;
  background: #00cb7a;
  background: -moz-linear-gradient(left, #00cb7a 1%, #0091c8 100%);
  background: -webkit-linear-gradient(left, #00cb7a 1%, #0091c8 100%);
  background: linear-gradient(to right, #00cb7a 1%, #0091c8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00cb7a', endColorstr='#0091c8', GradientType=1);
}

.connectBt.alt {
  background: #ff6f00;
  background: -moz-linear-gradient(left, #ff6f00 1%, #cb1717 100%);
  background: -webkit-linear-gradient(left, #ff6f00 1%, #cb1717 100%);
  background: linear-gradient(to right, #ff6f00 1%, #cb1717 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff6f00', endColorstr='#cb1717', GradientType=1);
}

.projectTitle-wrap {
  background: #232323;
  padding: 15px 0;
}

.projectTitle {
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.projectTitle-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.projectTitle-right .demoLink {
  margin-top: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.projectTitle-left h2 {
  font-size: 25px;
  font-weight: 600;
}

.projectTitle-left h2 span {
  font-weight: 400;
  font-size: 20px;
}

.cont-research {
  color: #FFF;
}

.cont-research-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #707070;
  padding: 25px 0;
}

.cont-research-head h2 {
  font-size: 17px;
  font-weight: 500;
  color: #CE88FF;
}

.research-head-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.roundInp {
  border-radius: 50px;
  background: #000;
  border: 1px solid #707070;
  font-size: 15px;
  padding: 6px 25px;
  color: #FFF;
}

.research-head-right .roundInp {
  min-width: 250px;
  height: 44px;
  margin-left: 15px;
}

.cont-research-body {
  padding-top: 40px;
}

.research-block {
  background: #070707;
  border-radius: 25px;
  padding: 20px;
}

.research-block-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.research-block-top-left {
  width: calc(100% - 160px);
}

.research-block-top-right {
  width: 147px;
}

.search-icon {
  position: relative;
}

.search-icon .fa {
  position: absolute;
  left: 20px;
  top: 18px;
  color: #979797;
}

.search-icon input[type="text"],
.search-icon select {
  border-radius: 10px;
  background: #000;
  border: 1px solid #707070;
  font-size: 15px;
  padding: 15px 25px;
  color: #FFF;
  padding-left: 50px;
  width: 100%;
}

.search-icon select {
  padding-left: 25px;
  padding-top: 14px;
  padding-bottom: 14px;
}

.cont-scroll {
  margin-top: 30px;
}

.research-block.cont-scroll {
  height: calc(100vh - 430px);
  overflow-x: hidden;
  overflow-y: auto;
}

.cont-scroll-block {
  border: 2px solid #484848;
  border-radius: 15px;
  padding: 20px;
  margin-top: 25px;
}

.cont-scroll-block:first-child {
  margin-top: 0;
}

.scroll-block-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scroll-block-top h2 {
  font-size: 14px;
  font-weight: 600;
}

.scroll-block-top h3 {
  font-size: 12px;
  color: #6E6E6E;
  font-style: italic;
}

.scroll-block-top h3 a {
  font-style: normal;
  color: #AC94FF;
}

.scroll-block-content {
  display: flex;
  margin-top: 30px;
}

.scroll-block-content-img {
  width: 71px;
}

.scroll-block-content-img img {
  width: 71px;
  height: 71px;
  border-radius: 12px;
}

.scroll-block-content-txt {
  width: calc(100% - 71px);
  padding-left: 20px;
}

.scroll-block-content-txt p {
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.scroll-block-content-bottom p {
  font-size: 12px;
  padding-top: 20px;
  color: #676767;
}

.cont-scroll::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

.cont-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.cont-scroll::-webkit-scrollbar-thumb {
  background-color: #e512c9;
  border-radius: 10px;
}


.txtEdit {
  background: #262626;
  border: 1px solid #707070;
  border-radius: 10px;
}

.txtEdit-top {
  display: flex;
  align-items: center;
  padding: 15px;
}

.txtEdit-nav {
  border-left: 1px solid #707070;
  padding: 0 15px;
}

.txtEdit-nav:first-child {
  border-left: 0;
  padding-left: 0;
}

.txtEdit-nav ul li {
  display: inline-block;
  cursor: pointer;
  font-weight: 700;
  padding: 0 5px;
}

.txtEdit-nav ul li sup {
  font-weight: 400;
}

.txtEdit-nav ul li .fa {
  font-weight: 400;
}

.txtEdit-nav select {
  font-size: 12px;
  border-radius: 5px;
  border: none;
}

.txtEdit-main {
  padding: 15px;
  border-top: 1px solid #707070;
}

.txtEdit-main textarea {
  background: none;
  width: 100%;
  border: none;
  height: 200px;
  font-size: 14px;
  color: #FFF;
}

.txtEdit-foot {
  padding: 15px;
  border-top: 1px solid #707070;
}

.txtEdit-foot p {
  padding: 0;
  font-size: 14px;
}


.create-course-top {
  background: #232323;
  padding: 18px 0;
}

.create-course-top h2 {
  font-size: 28px;
  font-weight: 600;
  color: #CE88FF;
}

.create-course-top h2 span {
  color: #CE88FF;
}

.create-course-mid {
  color: #FFF;
  background: #303030;
  padding: 80px 0;
}

.create-course-mid h2 {
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 600;
  padding-bottom: 15px;
}

.courseInp {
  border-radius: 10px;
  background: #000;
  border: 1px solid #707070;
  font-size: 14px;
  padding: 15px 25px;
  color: #FFF;
  width: 100%;
}

.course-step {
  padding-top: 40px;
}

.course-step ul {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.course-step ul li {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.course-step ul li span {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 66px;
  border-radius: 10px;
  overflow: hidden;
  padding-left: 5px;
  color: #FFF;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
  background: #C14FC9;
  background: -moz-linear-gradient(top, #C14FC9 0%, #D58989 100%);
  background: -webkit-linear-gradient(top, #C14FC9 0%, #D58989 100%);
  background: linear-gradient(to bottom, #C14FC9 0%, #D58989 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#C14FC9', endColorstr='#D58989', GradientType=0);
}

.course-step ul li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 66px;
  border-radius: 10px;
  overflow: hidden;
  padding-left: 5px;
  color: #FFF;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
  background: #9708e9;
  background: -moz-linear-gradient(top, #9708e9 0%, #4c0475 100%);
  background: -webkit-linear-gradient(top, #9708e9 0%, #4c0475 100%);
  background: linear-gradient(to bottom, #9708e9 0%, #4c0475 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9708e9', endColorstr='#4c0475', GradientType=0);
}

.course-step ul li a p {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
}

.course-step-img {
  transform: translateY(2px);
}

.course-step-img img {
  height: 52px;
}

.course-step-icon {
  width: 54px;
  height: 66px;
  text-align: center;
  line-height: 66px;
  background: rgba(0, 0, 0, 0.2);
}

.videoProject.alt {
  border-top: 2px solid #707070;
}



.custom-check {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 25px;
  width: 25px;
  margin: 0 !important;
}

.custom-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #1C1C1C;
  border: 1px solid #707070;
}

.custom-check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-check input:checked~.checkmark:after {
  display: block;
}

.custom-check .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}




.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
  margin: 0 !important;
  padding: 0 !important;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #5882D9;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background: #00D997;
}

input:focus+.slider {
  box-shadow: 0 0 1px #C23636;
}

input:checked+.slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* editor text textarea css */
.textarea-block-wrap {
  background: #303030;
  border-radius: 10px;
  padding: 15px;
  margin-top: 30px;
}

.textarea-block-wrap .form-control {
  background-color: #000 !important;
  color: #fff !important;
  border: 1px solid #707070;
}

.textarea-block-wrap .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

.textarea-block-wrap h2 {
  font-size: 16px;
  font-weight: 600;
}



/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch-title {
  font-size: 14px;
  padding-right: 10px;
  color: #6E6E6E;
  font-weight: 600;
}

.backBtn {
  background: #e512c9;
  border-radius: 5px !important;
  padding: 10px 30px !important;
  font-weight: 600;
  font-size: 15px;
  border: none;
  background: -moz-linear-gradient(left, #AC46B4 0%, #4F94C9 100%);
  background: -webkit-linear-gradient(left, #AC46B4 0%, #4F94C9 100%);
  background: linear-gradient(to right, #AC46B4 0%, #4F94C9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#AC46B4', endColorstr='#4F94C9', GradientType=1);
}

.textarea-btn {
  border-radius: 5px !important;
  padding: 6px 9px !important;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  border: none;
  background: linear-gradient(to right, #AC46B4 0%, #4F94C9 100%);
  background: -moz-linear-gradient(left, #AC46B4 0%, #4F94C9 100%);
  background: -webkit-linear-gradient(left, #AC46B4 0%, #4F94C9 100%);
}

/* Footer */
.appFooter {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background: #FFF;
  padding: 15px 0;
  color: #000;
}

.appFooter.innerPage {
  position: static;
  background: #003297;
}

.appFooter.innerPage p {
  color: #FFF;
}

.appFooter.innerPage .footer-single ul li a {
  color: #FFF;
}

.footer-single ul li {
  display: inline-block;
  margin-left: 6px;

}

.footer-single ul li:first-child {
  margin-left: 0;
}

.footer-single ul li a {
  display: inline-block;
  font-size: 14px;
  color: #000;
}

.footer-single ul li a:hover {
  color: blue;
}

.footer-single p {
  color: #000;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}

.footer-single p a {
  color: var(--secondary-color);
}

.footer-single.reelLogo {
  text-align: right;
}

.footer-single.reelLogo img {
  /* width: 130px; */
  height: auto !important;
  width: 180px !important;
}

/* Footer */

.navExpand {
  display: none;
}

.modal .ecommercebox .modal-header {
  border-bottom: 1px solid rgba(255, 255, 255, .1);
}

.ecommercebox .modal-body .modal-scrollbar {
  height: auto;
}

.groupbtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, .1);
  margin: 0 -15px;
  margin-top: 15px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.groupbtn .demoLink {
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
}

.ecommercebox .modal-body .form-group {
  width: 100%;
}

.formCont {
  padding: 30px 0;
}

.VideoModal.show-modal {
  background: rgba(0, 0, 0, 0.6);
}


.itemDisp-right select {
  border: none;
  background: #171717;
  border-radius: 5px;
  color: #FFF;
  font-size: 14px;
  padding: 8px 5px;
  width: 200px;
}

.itemDisp-right select:focus {
  outline: 0;
  border: none;
  box-shadow: none;
}

.itemDisp-right button {
  display: inline-block;
  font-size: 13px;
  color: #FFF;
  background: #000000;
  border: 1px solid #707070;
  border-radius: 50px;
  padding: 5px 15px;
}

/* .itemDisp-right button:hover {
  background: #5d00ff;
  border: 1px solid #5d00ff;
} */

.editorReelwrap {
  position: relative;
}


/* Editor CSS */
.editor-left {
  background: #191919;
  height: calc(100vh - 210px);
  width: 450px;
  position: fixed;
  left: 0;
  top: 80px;
  color: #FFF;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 30px;
  z-index: 100;
}

.editor-left p {
  font-size: 13px;
}

.editor-left::-webkit-scrollbar {
  width: 4px;
}

.editor-left::-webkit-scrollbar-thumb {
  background-color: #6D4780;
  border-radius: 10px;
}

.editor-right {
  width: 100%;
  padding: 0 0 150px 450px;
}

.campaignBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #232323;
  padding: 5px 50px;
}

.campaignBar .demoLink {
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  margin-left: 10px;
}

.campaignBar .demoLink img {
  margin-right: 10px;
}

.campaignNav {
  padding: 12px 0px;
  background: #232323;
  padding: 5px 0px;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.3);
}

.campaignNav .nav {
  align-items: center;
  justify-content: center;
}

.campaignNav .nav-link {
  /* width: 80px; */
  height: 56px;
  background: #414141;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF !important;
  margin: 3px 3px;
  cursor: pointer;
  padding: 0 10px;
}

/* .VideoModal-forintro .tab-content{ min-height: 250px;} */

.videoControll-nav ul li svg {
  transform: translateY(-3px);
  font-size: 18px;
}

.addReelBt svg {
  font-size: 40px;
  color: #838383;
}

.addReelBt svg:hover {
  color: #e1a6ff;
}

.edit-input-font-size {
  color: #fff !important;
  border-radius: 4px !important;
  border: 1px solid #707070;
  background-color: #0C0C0C !important;
  height: auto;
  box-shadow: none;
  resize: none;
  width: 100%;
  height: 37px !important;
  letter-spacing: 1px;
  padding-left: 8px;
  font-size: 13px;
  outline: 0px !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.edit-input-lineheight {
  color: #fff !important;
  border-radius: 4px !important;
  border: 1px solid #707070;
  background-color: #0C0C0C !important;
  box-shadow: none;
  resize: none;
  width: 100%;
  height: 41px !important;
  letter-spacing: 1px;
  padding-left: 8px;
  font-size: 13px;
  outline: 1px !important;
}

.campaignNav .nav-link.active,
.campaignNav .nav-link:hover {
  background: #AC46B4;
  background: -moz-linear-gradient(left, #AC46B4 0%, #4F94C9 100%);
  background: -webkit-linear-gradient(left, #AC46B4 0%, #4F94C9 100%);
  background: linear-gradient(to right, #AC46B4 0%, #4F94C9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#AC46B4', endColorstr='#4F94C9', GradientType=1);
}

.campaignNav .nav-link span {
  display: block;
  width: 100%;
  text-align: center;
}

.campaignNav .nav-link span img {
  height: 18px;
}

.campaignNav .nav-link span+span {
  font-size: 12px;
  padding-top: 2px;
}

.editorReel {
  background-color: #000;
  bottom: 0;
  padding: 20px 100px 10px 100px;
  position: fixed;
  width: 100%;
  height: 130px;
  z-index: 6;
}

.editorReel-scroll::-webkit-scrollbar {
  height: 5px;
}

.editorReel-scroll::-webkit-scrollbar-thumb {
  background-color: #6D4780;
  border-radius: 10px;
}

.editorReel-scroll {
  align-items: center;
  display: flex;
  overflow-x: auto !important;
  overflow-y: hidden;
  padding-bottom: 5px;
}

.editorReel-scroll ul {
  display: flex;
  white-space: nowrap;
  position: relative;
}

.editorReel-scroll ul li {
  display: inline-block;
  margin-left: 9px !important;
  margin-right: 9px;
}

.editorReel-scroll ul li:first-child {
  margin-left: 0;
}

.editorReel-single {
  width: 150px;
  height: 80px;
  background: #101010;
  border: 2px solid #434343;
  border-radius: 10px;
  position: relative;
  color: #FFF;
  text-align: center;
  overflow: hidden;
}

.editorReel-scroll ul li.selected-tile-hover .editorReel-single {
  border: 2px solid #434343 !important;
}

.editorReel-dragmessage {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 12px;
  text-align: center;
}

.addReelBt {
  position: absolute;
  right: -50px;
  top: 50%;
  margin-top: -17px;
  cursor: pointer;
}



.canvasEditor-main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  /* border-top-left-radius: 15px;
  border-top-right-radius: 15px; */
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: #000000b5;
}

/*----------- 16X9 editor class and css--------------- */


.canvas_16X9_editor {
  max-width: 640px;
  max-height: 360px;
  margin: 20px auto;
}


/*-----------End- 16X9 editor class and css--------------- */


/*-----------9x16 editor class and css--------------- */


.canvas_9x16_editor {
  width: 360px;
  height: 640px;
  margin: 20px auto;

}

.canvas_9X16_height_scroll {
  padding-bottom: 160px;
}

/*----------- End-9x16 editor class and css--------------- */



/*----------- 1x1 editor class and css--------------- */



.canvas_1x1_editor {
  width: 460px;
  height: 460px;
  margin: 20px auto;

}

.canvas_1X1_height_scroll {
  padding-bottom: 80px;
}

/*----------- End-1x1 editor class and css--------------- */



/* .canvasEditor-main iframe,
.canvasEditor-main canvas,
.canvasEditor-main video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
} */
.video-container {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 0;
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  display: block;
}

.videoControll {
  height: 60px;
  padding: 0 25px;
  background: #000;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color: #FFF;
}

.hover-video-player video {
  height: 100% !important;
}

.videoControll-txt {
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.videoControll-txt span {
  font-size: 14px;
}

.videoControll-txt span+span {
  margin-left: 15px;
}

.videoControll-nav ul {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.videoControll-nav ul li {
  margin: 0 5px;
  font-size: 22px;
  cursor: pointer;
}

.videoControll-nav ul li .fa-play {
  margin-left: 3px;
}

.videoControll-nav ul li:hover {
  color: #B88AFF;
}

.videoControll-right {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 18px;
}

.story-block ul li {
  margin-top: 15px;
}

.story-block ul li:first-child {
  margin-top: 0;
}

.story-block-single {
  background: #242424;
  border: 1px solid #707070;
  border-radius: 12px;
  padding: 20px;
}

.story-block ul li:hover .story-block-single,
.story-block ul li.active .story-block-single {
  background: #6D4780;
  border: 1px solid #6D4780;
}

.inTabNav.nav {
  flex-wrap: nowrap;
  background: #000000;
  border-radius: 10px;
  overflow: hidden;
}

.inTabNav.nav .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  border-left: 1px solid #1A1A1A;
}

.inTabNav.nav .nav-item:first-child {
  border-left: 0;
}

.inTabNav.nav .nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #6D6D6D !important;
  font-size: 13px;
  padding: 15px 5px;
  text-align: center;
  height: 100%;
  border-radius: 0 !important;
}

.inTabNav.nav .nav-link.active {
  color: #FFF !important;
  background: #e1a6ff !important;
}

.tabSearch:hover>button.demoLink::before {
  width: 100%;
}

.tabSearch:hover>button.demoLink {
  color: var(--btn-hover-color) !important;
  transition: .3s ease-in-out;
}

.inTabNav.nav .nav-item:first-child,
.inTabNav.nav .nav-item:nth-child(2),
.inTabNav.nav .nav-item:nth-child(3) {
  max-width: 82px;
}

.innertab {
  padding-top: 20px;
}

.tabSearch {
  position: relative;
}

.tabSearch input[type="text"] {
  width: 100%;
  border: none;
  background: #FFF;
  font-size: 14px;
  padding: 10px 15px;
  padding-left: 40px;
  border-radius: 10px;
}

.inp-icon {
  position: absolute;
  left: 15px;
  top: 9px;
  color: #979797;
}

.myImage-respo img {
  width: 100%;
}

.myImage-section {
  position: relative;
}


.media-ul-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -1px;
  margin-left: -1px;
  /* padding: 8px; */
}

.media-ul-list li {
  margin-top: 2px;
  width: 100%;
  min-height: 1px;
  padding-right: 1px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 1px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.media-ul-list li:first-child,
.media-ul-list li:nth-child(2) {
  margin-top: 0;
}

.mediaList-single {
  height: 110px;
  border: 1px solid #707070;
  position: relative;
}

.hover-loader-center {
  position: absolute;
  top: 30%;
  left: 42%;
  transform: translate(-50%, -50%);
  color: #ea07ea;
  font-size: 30px;
}

.innertab-scroll {
  margin-top: 20px;
  height: calc(100vh - 370px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 15px;
  margin-right: -15px;
}

.innertab-scroll::-webkit-scrollbar {
  width: 6px;
}

.innertab-scroll::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  border-radius: 10px;
}

.mediaImg {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.tabSearch .demoLink {
  padding-top: 8px;
  padding-bottom: 8px;
}

.inTabNav.full .nav-item {
  max-width: 100% !important;
}

.audioList-single {
  border-radius: 10px;
  overflow: hidden;
  background: #4F94C9;
  background: -moz-linear-gradient(top, #4F94C9 0%, #C14FC9 100%);
  background: -webkit-linear-gradient(top, #4F94C9 0%, #C14FC9 100%);
  background: linear-gradient(to bottom, #4F94C9 0%, #C14FC9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4F94C9', endColorstr='#C14FC9', GradientType=0);
  border: none;
}

.audioList-single-music li .audioList-single {
  margin-top: 6px !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
  margin-bottom: 6px !important;

}

.audioList-top {
  position: relative;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.waveIcon {
  max-width: 75px;
}

.audio-time {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 11px;
  font-weight: 500;
}

.audioList-bottom {
  height: 40px;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.audioList-bottom .fa {
  margin-right: 8px;
  font-size: 20px;
}

.audioList-bottom p {
  line-height: 14px;
}

.innertab-scroll.alt {
  margin-top: 0;
  padding-top: 0;
  height: calc(100vh - 310px);
}

.absInp {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  opacity: 0;
  cursor: pointer;
}

.ai-voice-block {
  background: #303030;
  border-radius: 10px;
  padding: 10px;
}

.voice-block-top select {
  width: 100%;
  background: #000;
  color: #FFF;
  border-radius: 4px;
  border: 1px solid #707070;
  font-size: 13px;
  padding: 8px 5px;
}

.voice-block-top .row {
  margin-left: -5px;
  margin-right: -5px;
}

.voice-block-top [class*='col-'] {
  padding-left: 5px;
  padding-right: 5px;
}

.optDesc {
  display: block;
  text-align: center;
  font-size: 11px;
  color: #7D7D7D;
  padding-top: 5px;
}

.voice-block-bottom .demoLink {
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.voice-block-bottom .demoLink svg {
  margin-right: 5px;
}

.voice-block-bottom .demoLink img {
  margin-right: 10px;
}

.voice-block-mid {
  padding-top: 20px;
  padding-bottom: 10px;
}

.voice-block-mid textarea {
  background: #0C0C0C;
  border: 1px solid #707070;
  border-radius: 12px;
  width: 100%;
  height: 170px;
  font-size: 14px;
  color: #FFF;
  padding: 15px;
}

.add-block-single h2 {
  font-size: 16px;
  color: #FFF;
  font-weight: 500;
}

.add-block-main {
  background: #303030;
  border-radius: 10px;
  padding: 15px;
  margin-top: 15px;
}

.fileUpload {
  position: relative;
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #171717;
  border: 1px dashed #707070;
  font-size: 14px;
  border-radius: 10px;
}

.fileUpload .fa {
  font-size: 20px;
  margin-right: 10px;
}

.fileUpload input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0;
}

.itemDisp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}

.itemDisp-left ul {
  margin: 0;
  padding: 0;
}

.itemDisp-left ul li {
  display: inline-block;
  width: 56px;
  height: 56px;
  background: #171717;
  border: 1px solid #686868;
  vertical-align: top;
  border-radius: 100%;
  margin-left: 10px;
}

.itemDisp-left ul li:first-child {
  margin-left: 0;
}

.uploadImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.add-block-single {
  margin-top: 30px;
}

.add-block-single:first-child {
  margin-top: 0;
}

.add-block-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-block-title a {
  display: inline-block;
  font-size: 13px;
  color: #FFF;
  background: #000000;
  border: 1px solid #707070;
  border-radius: 50px;
  padding: 5px 15px;
}

.add-block-title a:hover {
  background: #e512c9;
  border: 1px solid #e512c9;
}

.add-block-main h3 {
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 10px;
}

.motionlist ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.motionlist ul li {
  /* width: 100%; */
  min-height: 1px;
  padding-right: 7px;
  padding-left: 5px;
  /* -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33%;
  flex: 0 0 33.33%;
  max-width: 33.33%; */
}

.motionlist-single {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 50px;
  background: #0C0C0C;
  border: 1px solid #0C0C0C;
  border-radius: 10px;
  font-size: 13px;
  color: #787878;
  font-weight: 600;
  margin-top: 10px;
  cursor: pointer;
}

.motionlist-single:hover,
.motionlist-single.active {
  border: 1px solid #D671FF;
}

.transition-single {
  position: relative;
  height: 118px;
  cursor: pointer;
  margin: 5px 0;
}

.transition-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.transition-txt {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.txt-format select {
  width: 100%;
  background: #0C0C0C;
  color: #FFF;
  border-radius: 4px;
  border: 1px solid #707070;
  font-size: 13px;
  padding: 8px 5px;
}

.txt-format .row {
  margin-left: -5px;
  margin-right: -5px;
}

.txt-format [class*='col-'] {
  padding-left: 5px;
  padding-right: 5px;
}

.txt-format h2 {
  font-size: 16px;
  font-weight: 600;
}

.txt-format input[type="text"] {
  width: 100%;
  background: #0C0C0C;
  color: #FFF;
  border-radius: 4px;
  border: 1px solid #707070;
  font-size: 13px;
  padding: 8px 5px;
}

.fontStyle-block {
  margin-top: 16px;
}

.font-format {
  background: #0C0C0C;
  border: 1px solid #707070;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 18px;
}

.font-format .fa {
  font-size: 16px;
}

.fontStyle-block-wrap {
  background: #303030;
  border-radius: 10px;
  padding: 15px;
  margin-top: 30px;
}

.inpWrap {
  position: relative;
}

.colorValue {
  position: absolute;
  left: 6px;
  top: 5px;
  width: 27px;
  height: 27px;
  background: #FFF;
  border-radius: 5px;
}

span.label {
  display: block;
  text-align: center;
  color: #7D7D7D;
  font-size: 12px;
  font-weight: 600;
  padding-top: 5px;
}

.color-palette ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -1px;
  margin-left: -1px;
}

.color-palette ul li {
  width: 100%;
  min-height: 1px;
  padding-right: 1px;
  padding-left: 1px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 9.09%;
  flex: 0 0 9.09%;
  max-width: 9.09%;
  margin-top: 2px;
}

.color-palette-single {
  height: 30px;
  background: #009DB0;
  border-radius: 2px;
}

.fontStyle-block-wrap .itemDisp {
  margin-top: 0;
}

/* .fontStyle-block-wrap .fontStyle-block{ margin-top: 0;} */

/* Editor CSS */



/* Modification */
body {
  background: #fbfbfb;
  font-family: 'Montserrat', Sans-serif;
}

/* .logo img{ width: 190px;} */
.siteWrap {
  padding-top: 80px;
}

.footer-single ul li a {
  font-size: 14px;
  font-weight: 500;
}

.lessonLink-right p {
  line-height: 1.75;
  color: #FFF;
}

.featureSec-single h6 {
  font-family: 'Montserrat', Sans-serif;
  font-size: 20px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 2px;
  color: #7B7B7B;
  text-transform: uppercase;
  padding-bottom: 14px;
}

.featureSec-single h2 {
  font-family: 'Montserrat', Sans-serif;
  font-size: 32px;
  line-height: 1.22;
  font-weight: 600;
  color: #000;
}

.featureSec-single p {
  font-size: 20px;
  line-height: 1.6;
  color: #000;
}

.textL {
  font-weight: 500;
}

.lessonLink-right h6 {
  font-family: 'Montserrat', Sans-serif;
}

.lessonLink-right h2 {
  font-family: 'Montserrat', Sans-serif;
  font-size: 32px;
  line-height: 1.22;
  font-weight: 700;
}

/* .videoProject-single {
  background: none;
  padding: 0;
  border-radius: 0;
  border: none;
}

.videoProject-single-top {
  border-radius: 0;
} */

.featureSec-left {
  padding-right: 0;
  padding-left: 120px;
}

.featureSec-single:nth-child(even) .featureSec-left {
  padding-left: 0;
  padding-right: 120px;
}

.videoProject-single-top {
  height: 270px;
  min-height: 270px;
}

.vidThumb {
  height: 250px;
}

.videoProject-single-awating {
  height: 250px;
}

.videoProject-single-bottom-desc {
  font-size: 14px;
}

.videoProject-single-bottom-desc span {
  font-size: 13px;
  display: inline-block;
  padding-top: 8px;
}

.vidHover {
  top: 100%;
  background: none;
  z-index: 2;
}

.videoProject-single:hover .vidHover {
  top: 0;
}

.videoProject-single-top::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.videoProject-single:hover .videoProject-single-top::after {
  opacity: 1;
  visibility: visible;
}


.featureSec-right {
  margin-right: 0;
  padding-right: 30px;
}

.featureSec-single:nth-child(even) .featureSec-right {
  margin-right: 0;
  margin-left: 0;
  padding-left: 30px;
}

.proView span i {
  transform: translateY(4px);
}

.hero-banner-right {
  padding-left: 0 !important;
}

.bannerLink {
  margin: 10px 0;
  height: 90px;
  border-radius: 0;
  position: relative;
  cursor: pointer;
}

.absInput {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.hero-banner {
  overflow: hidden;
}

.hero-banner-txt {
  z-index: 1;
}

.hero-banner::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(to right, #00b7df 0%, #c75aff 100%);
  opacity: 0;
  visibility: hidden;
}

.hero-video {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding-bottom: 56.25%;
}

.hero-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* transform: translateY(-150px); */
}

.bannerLink {
  background: linear-gradient(to bottom, rgba(0, 85, 255, 0.7) 0%, rgba(0, 43, 128, 0.7) 100%);
  cursor: pointer !important;
  border-radius: 10px;
}

.bannerLink.notClick {
  pointer-events: none;
}

.hero-banner-left h2 {
  font-size: 68px;
}

.hero-banner-left h3 {
  font-size: 68px;
  color: var(--primary-color);
}

.demoLink {
  padding-top: 15px;
  padding-bottom: 15px;
}

.demoLink-2 {
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-block;
  color: #FFF;
  font-weight: 600;
  padding: 14px 30px;
  border-radius: 50px;
  margin-top: 30px;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
  background: var(--primary-btn-color);
  background: linear-gradient(to right, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
}

.demoLink-chapter {
  display: inline-block;
  color: #FFF;
  font-weight: 600;
  min-width: 200px;
  border-radius: 5px;
  padding-top: 14px;
  margin-top: 26px;
  padding-bottom: 14px;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
  background: var(--primary-btn-color);
  background: linear-gradient(to right, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
}

.demoLink-chapter.sm {
  min-width: 150px;
  border-radius: 5px;
  padding-top: 14px;
  padding-bottom: 14px;
}

.hero-banner-left p {
  padding-right: 0 !important;
  color: #FFF;
}

.hero-banner-left {
  padding-right: 120px;
}

.VideoModal.white .modal-content {
  background: #FFF;
  color: #000;
  border-radius: 5px;
}

.VideoModal.small .modal-dialog {
  max-width: 850px !important;
}

.inpBtn.auto {
  display: inline-block;
  width: auto;
  min-width: 200px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.audioModal {
  padding: 76px 50px 33px;
  background-image: linear-gradient(139deg, #2c32b8 -9%, #7033d5 34%, #8133db 52%, #9933e6 69%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.modal-content {
  border: none;
}

#record {
  color: #8133db;
  max-width: 72px;
  max-height: 72px;
  min-height: 72px;
  padding: 20.5px;
  border: 0;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 24%);
  align-items: center;
  justify-content: center;
}

#record:hover {
  box-shadow: 0 4px 16px 0 rgb(0 0 0 / 55%);
}

#time-display {
  font-size: 96px;
  font-weight: 100;
  letter-spacing: 1.5px;
  font-weight: 100;
  font-family: 'Montserrat', sans-serif;
}

.modal-title {
  color: #fff;
}

.video-crop-header {
  width: 100%;
  margin: auto;
  padding: 40px 0 6px 0;
}

.progress {
  border-radius: 50px;
}

.progressWrap .bg-warning {
  background-color: #e1a6ff !important;
}

.render_wrapper .progress-bar.bg-warning {
  background-color: #8d25c8 !important
}

.render_wrapper .bg-warning {
  background-color: #555 !important;
}



.progressWrap .progress-bar {
  border-radius: 0;
}

.progressWrap .progress {
  background-color: #2a2a2a !important;
  border-radius: 0 !important;
}

.video-card-content {
  padding: 0 30px !important;
}

.video-card-content p {
  font-size: 14px !important;
}

.contWrap-list-single {
  overflow: hidden;
}

.progress-bar {
  border-radius: 50px;
  /* background: linear-gradient(190deg, #9857b9 0%, #6186c5 100%);; */
  transition: none;
}

.progress-bar-render {
  border-radius: 50px;
  /* background-image: -webkit-linear-gradient(94deg, #2c32b8 -4%, #7033d5 26%, #fff 38%, #fff 50%);
  background-image: linear-gradient(94deg, #2c32b8 -4%, #7033d5 26%, #fff 38%, #fff 50%); */
  /* background: repeating-linear-gradient(90deg, #fff, #fff 20px, #000 20px, #000 40px); */
  background-image: linear-gradient(45deg, rgb(255 255 255 / 0%) 25%, #fff9f9 25%, white 50%, rgb(255 255 255 / 0%) 50%, rgb(255 255 255 / 0%) 75%, white 75%, #f2eaea);
  transition: none;
}

.progress .bg-danger {
  background-color: #e512c9 !important;

}

.Font-line {
  position: absolute;
  display: inline-block;
  top: -10px;
  right: 16px;
  width: 1.3px;
  height: 51px;
  background: red;
  transform: rotate(45deg);
  margin: auto;
}

.progress-label {
  color: #9b33e9;
  font-size: 22px;
  font-weight: 700;
}

.modal-body {
  border-radius: 5px;
}

.modal-content p {
  color: #000;
}

.sp1 {
  font-weight: 600;
}

.uploadFile-wrap {
  padding: 15px;
}

.formSec {
  padding: 10px;
}

.inpField-inp {
  width: 100%;
  box-shadow: 0 2px 4px 0 rgb(155 51 233 / 35%);
  border: solid 1.8px #8133db;
  line-height: 1.5;
  padding: 20px;
  margin: 0;
  font: 400 16px/16px 'Montserrat', sans-serif;
  color: rgba(129, 51, 219, 0.35);
  color: #000;
  opacity: 1;
  box-shadow: 0 2px 4px 0 rgb(232 201 255 / 50%);
  border: solid 1.8px rgba(129, 51, 219, 0.35);
  background-color: rgba(129, 51, 219, 0.08);
}

.inpBtn {
  width: 100%;
  box-shadow: 0 2px 4px 0 rgb(155 51 233 / 35%);
  border: solid 1.8px #8133db;
  line-height: 1.5;
  padding: 20px;
  margin: 0;
  background: #fff;
  font: 700 16px/16px 'Montserrat', sans-serif;
  color: #fff;
  background: #FFF;
  opacity: 1;
  box-shadow: 0 2px 4px 0 rgb(232 201 255 / 50%);
  border: solid 1.8px rgba(129, 51, 219, 0.35);
  background: -moz-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
  background: -webkit-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
  background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
}

.create-modal-btn {
  background: linear-gradient(to right, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%) !important;
  color: #fff;
  border: none;
  box-shadow: none;
  border-radius: 50px;
  position: relative;
  z-index: 1;
}

.create-modal-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 0%;
  height: 100%;
  z-index: -1;
  transition: .3s ease-in-out;
  border-radius: 30px;
}

.create-modal-btn:hover {
  color: var(--btn-hover-color) !important;
  transition: .3s ease-in-out;
}

.create-modal-btn:hover:before {
  width: 100%;
  color: #fff !important;
  transition: .3s ease-in-out;
}

.inpField label {
  display: block;
  color: #000;
  font: 700 16px/16px 'Montserrat', sans-serif;
  padding-bottom: 5px;
  font-size: 17px;
}

.inpBtn.alt {
  background: #FFF !important;
  width: auto;
  min-width: 200px;
}

.create-course-top h2 {
  font-size: 17px;
}

.create-course-mid h2 {
  font-size: 26px;
}

.courseBtn .demoLink {
  min-width: 200px;
  border-radius: 5px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 0;
  padding-top: 14px;
  padding-bottom: 14px;
}

.create-course-mid.alt {
  background: none;
}

.recomnd-single h6 {
  font-weight: 600;
  font-size: 16px;
}

.recomnd {
  padding-top: 40px;
}

.recomnd ul li {
  border-top: 1px solid #6a6a6a;
  padding: 25px 0;
}

.recomnd .demoLink {
  min-width: 150px;
  border-radius: 5px;
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: center;
  background: linear-gradient(to right, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  color: #fff;
  /* border: 1px solid #707070; */
}

.editorReel-single img {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
}

.demoLink.sm {
  min-width: 150px;
  border-radius: 5px;
  padding-top: 14px;
  padding-bottom: 14px;
}

textarea.courseInp {
  height: 150px;
}

.inpWithBtn {
  position: relative;
  margin-top: 40px;
}

.inpWithBtn .demoLink {
  position: absolute;
  right: 10px;
  bottom: 15px;
}

.course-step ul li a p {
  color: #FFF;
  font-weight: 500;
  text-transform: none;
  font-size: 17px;
}

.course-step ul li span p {
  color: #FFF;
  font-weight: 500;
  text-transform: none;
  font-size: 12px;
}

.vidThumb {
  transform: scale(1.03);
}

.backBtn {
  color: #FFF;
}

.cont-research-body.modified .search-icon input[type="text"],
.search-icon select {
  padding-left: 25px;
}

.search-icon .demoLink {
  margin: 0;
}

.research-block-top-right {
  width: 186px;
}

.research-block-top-left {
  width: calc(100% - 194px);
}

.research-body-left .recomnd ul li:first-child {
  padding-top: 0;
  border: none;
}

.txtEdit-nav ul li .demoLink {
  width: auto;
  min-width: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.txtEdit-main textarea {
  height: calc(100vh - 455px);
}

.research-block.cont-scroll {
  height: calc(100vh - 394px);
}

.inteTop-single {
  width: 120px;
  height: 120px;
}

.inteTop-single img {
  max-height: 40px;
}

.connection-box-main {
  display: flex;
  justify-content: space-between;
}

.connection-img {
  width: 60px;
}

.connection-txt {
  width: calc(100% - 80px);
}

.noConnect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.noConnect .youbtube-connection {
  width: calc(100% - 156px);
}

.noConnect .youtube-content {
  width: 100%;
}

.noConnect h6 {
  border-bottom: 1px solid #c4d7ff;
  padding-bottom: 5px;
}

.facebook-connection {
  border-bottom: 1px solid #c4d7ff;
}

.facebook-connect-account button,
.facebook-delete button {
  display: inline-block;
  color: #FFF;
  font-weight: 600;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
  border-radius: 4px;
  background: var(--primary-btn-color);
  background: -moz-linear-gradient(left, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  background: -webkit-linear-gradient(left, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  background: linear-gradient(to right, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='var(--primary-btn-color)', endColorstr='var(--secondary-btn-color)', GradientType=1);
}

.facebook-connect-account button:focus {
  border: none;
  box-shadow: none;
  outline: 0;
}


.btn-change7 {
  box-shadow: 0 0 1px #ccc;
  box-shadow: inset 0 0 0 #fff;
  cursor: pointer;
  transition-duration: .3s;
  transition-timing-function: linear;
}


.VideoModal.small .modal-body {
  padding: 40px;
}

.inpField-inp {
  box-shadow: none;
  background: #FFF;
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  color: #000 !important;
}

.campaignBar-inp {
  background: #161616;
  border: 1px solid #707070;
  font-size: 14px;
  color: #FFF;
  padding: 7px 15px;
  border-radius: 50px;
}

.impTopic-single img {
  max-width: 80px;
}

.contWrap {
  padding: 30px 0;
  color: #FFF;
}

.contWrap-title {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #707070;
  padding-bottom: 15px;
}

.contWrap-title h2 {
  font-weight: 500;
  font-size: 22px;
}

.contWrap-title h2 span {
  display: inline-block;
  padding: 0 10px;
}

.contWrap-title h2 svg {
  color: #E1A6FF;
  font-size: 30px;
}

.contWrap-list {
  padding-top: 25px;
}

.contWrap-list-single {
  background: #000000;
  border: 1px solid #979797;
  border-radius: 10px;
  height: 400px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
}

.contWrap-list-single.add {
  border: 2px dashed #979797;
}

.contWrap-txt h5 {
  font-weight: 500;
  font-size: 17px;
  padding-bottom: 5px;
}

.largeIcon {
  font-size: 150px;
}

.contWrap-list-single-select {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #E1A6FF;
  font-size: 30px;
}

.contWrap-list-single.active {
  border: 1px solid #AC46B4;
}

.campaign-name {
  padding-top: 30px;
}

.campaign-name-mid {
  height: 100%;
  background: #000000;
  border: 1px solid #979797;
  border-radius: 15px;
  padding: 30px;
}

.campaign-name-inp {
  background: #242424;
  border: 1px solid #707070;
  /* height: 10%; */
  min-height: 90px;
  border-radius: 15px;
  padding: 20px 25px;
  margin-top: 3%;
}

.progressWrap {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
}

.campaign-name-inp:first-child {
  margin-top: 0;
}

.campaign-name-inp .row {
  height: 100%;
}

.campaign-name-inp label {
  margin: 0;
  padding: 0;
}

.campaign-name-inp-box {
  width: 100%;
  border: none;
  background: #000000;
  color: #FFF;
  border: 1px solid #707070;
  border-radius: 15px;
  font-size: 15px;
  padding: 15px;
}

.textarea-wrap {
  height: 45%;
  margin-top: 3%;
  position: relative;
}

textarea.campaign-name-inp-box {
  height: 100%;
  background: #292929;
}

.promo-select {
  background: #121212;
  border: 1px solid #707070;
  border-radius: 15px;
  height: 70px;
  display: flex;
  align-items: center;
  float: right;
  padding-right: 25px;
}

.campaign-name-inp .demoLink {
  width: 100%;
  margin: 0;
  padding: 12px 0;
}

.videoProject-single.alt {
  background: linear-gradient(22deg, #004A5A 0%, #9423CE 100%) !important;
  border: 1px solid #FFF;
  padding: 15px;
}

.videoProject-single.alt .dashboard-card-bg {
  background: none !important;
}

.videoProject-single.alt .dashboard-card-bg-2 {
  background: #660013 !important
}

.videoProject-single.alt .videoProject-single-top {
  background: #171717;
  border: 1px solid #ACACAC;
}

.videoProject-single-edIcon {
  width: 40px;
  height: 40px;
  background: #001C3D;
  border: 1px solid #518cd1;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-size: 20px;
}

.videoProject-single-edName {
  width: calc(100% - 50px);
  background: #001C3D;
  border: 1px solid #518cd1;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-radius: 50px;
  color: #000;
  font-weight: 500;
  font-size: 15px;
}

.video-card-content-2 img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  opacity: 0.8;
}

.demoLink,
.demoLink-2,
.manage-block button {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.demoLink::before,
.demoLink-2::before,
.manage-block button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -2px;
  background: #fff;
  width: 0%;
  height: 100%;
  z-index: -1;
  transition: .3s ease-in-out;
  border-radius: 30px;
  border: 1px solid #0099dd;
}

.demoLink:hover,
.demoLink-2:hover,
.manage-block button:hover {
  color: var(--btn-hover-color) !important;
  transition: .3s ease-in-out;
}

.demoLink:hover::before,
.demoLink-2:hover:before,
.manage-block button:hover:before {
  width: 100%;
  color: #fff !important;
  transition: .3s ease-in-out;
  left: 0px;
}

.btn-change7 {
  overflow: hidden;
}

/* .demoLink-2{  transition: .3s ease-in-out;}
.demoLink-2:hover{ box-shadow: 400px 0 0 #fff inset; transition: .3s ease-in-out; color: var(--btn-hover-color);} */

.backBtn,
.research-btn,
.demoLink-chapter {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.backBtn::before,
.research-btn:before,
.demoLink-chapter:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 0%;
  height: 100%;
  z-index: -1;
  transition: .3s ease-in-out;
  border-radius: 5px;
}

.backBtn:hover,
.research-btn:hover,
.demoLink-chapter:hover {
  color: var(--btn-hover-color) !important;
  transition: .3s ease-in-out;
}

.backBtn:hover::before,
.research-btn:hover::before,
.demoLink-chapter:hover:before {
  width: 100%;
  color: #fff !important;
  transition: .3s ease-in-out;
}

.recomnd .demoLink::before,
.courseBtn .demoLink::before {
  border-radius: 5px;
}

.recomnd .demoLink:hover {
  color: var(--btn-hover-color) !important;
  transition: .3s ease-in-out;
}

/* .helpSearch-right .demoLink::before {
  opacity: 0;
} */

/* .helpSearch-right .demoLink:hover {
  color: #FFF !important;
} */

.campaignBar .demoLink::before {
  opacity: 0;
  visibility: hidden;
}

.campaignBar .demoLink:hover {
  color: #FFF !important;
  opacity: 0.7;
}

.control-btn svg {
  height: 20px;
}

.control-btn:hover svg path {
  fill: #E1A6FF !important;
}

.control-btn:hover svg g {
  stroke: #E1A6FF !important;
}

.control-btn.disable a {
  cursor: not-allowed;
}

.control-btn.disable svg path {
  fill: #484848 !important;
}

.control-btn.disable svg g {
  stroke: #484848 !important;
}


.inpField {
  background: #2c2c2c;
  border: 1px solid #4e4e4e;
  padding: 10px 15px;
  width: 100% !important;
  color: #fff;
  font-size: 14px;
}

.motionlist-inp label {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.tempList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.tempList li {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.tempList-single {
  position: relative;
}


/* .btn{ position: relative; overflow: hidden; transition: all 0.5s ease;}
.btn:hover{ box-shadow: 400px 0 0 #fff inset; transition: .3s ease-in-out; color: var(--btn-hover-color);} */

.custom-radio {
  display: inline-block;
  position: relative;
  padding-left: 35px !important;
  margin: 0 0 0 25px !important;
  cursor: pointer;
  font-size: 17px;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-radio input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.custom-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  border: 4px solid #9D9D9D;
  border-radius: 50%;
}

.custom-radio input:checked~.checkmark {
  border: 4px solid #E1A6FF;
}

.custom-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio input:checked~.checkmark:after {
  display: block;
}

.custom-radio .checkmark:after {
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--primary-btn-color);
}

.contWrap-title .demoLink,
.translate-drop .demoLink {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0;
  margin-left: 10px;
}

.translate-drop .demoLink {
  min-width: 150px;
  padding-left: 0;
  padding-right: 0;
}

.contWrap-email {
  background: #000;
  padding: 25px;
  border-radius: 15px;
}

.contWrap-email-in {
  background: #262526;
  border: 1px solid #6b6a6c;
  border-radius: 15px;
}

.email-block-top {
  padding: 15px 20px;
  border-bottom: 1px solid #6b6a6c;
}

.email-block-top p span {
  color: #e1a6ff;
}

.email-block-bottom {
  padding: 15px 20px;
  border-top: 1px solid #6b6a6c;
  text-align: right;
}

.contText {
  margin: 0;
  padding: 15px 20px;
  border: none;
  background: none;
  display: block;
  width: 100%;
  color: #FFF;
  height: 400px;
}

.translate-drop {
  background: #262526;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #6b6a6c;
  border-radius: 15px;
  margin-top: 25px;
}

.translate-drop select {
  width: calc(100% - 150px);
  background: #000;
  border: none;
  height: 44px;
  color: #FFF;
  border-radius: 10px;
  padding: 0 10px;
}


.dashboard-card-bg {
  padding: 0 15px;
}


/*  */
.bannerLink-left {
  display: flex;
  align-items: center;
}

.bannerLink-left span {
  font-size: 40px;
  font-weight: 700;
  display: inline-block;
  text-align: left;
  width: 140px;
}

.videoProject,
.featureSec {
  background: #F2F3F7;
}

.business-wrap {
  padding: 40px 0;
}

.business-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.business-top h2 {
  font-size: 24px;
  color: var(--blue-color);
}

.demoLink {
  height: 54px;
  padding-top: 0;
  padding-bottom: 0;
  display: inline-flex;
  align-items: center;
}

.demoLink svg {
  font-size: 25px;
  margin-left: 10px;
}

.business-list {
  background: #e4ecff;
  border-radius: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 30px;
  color: #FFF;
}

.business-list.alt {
  background: #6784C1;
}

.business-search {
  display: flex;
  align-items: center;
}

.inpSearch {
  background: #FFF;
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid #b3c2e7;
}

input[type="text"].inpSearch {
  width: 375px;
}

select.inpSearch {
  border-radius: 5px;
}

.inpSearch.withIcon {
  padding-left: 50px;
}

.business-search-inp {
  position: relative;
}

.searchIcon {
  position: absolute;
  left: 15px;
  top: 2px;
  font-size: 25px;
  color: #000;
}

.businessTable {
  margin: 0;
}

.businessTable tr th {
  border: none;
  color: #2552af;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 30px;
}

.businessTable tr td {
  font-size: 14px;
  background:none;
  border: none;
  border-bottom: 2px solid #c5d1ef;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 500;
  color: #002981;
  vertical-align: middle !important;
}

.businessTable tr td:first-child{ padding-left: 20px;}

/* .businessTable tr td:first-child{ border-top-left-radius: 10px; border-bottom-left-radius: 10px;}
.businessTable tr td:last-child{ border-top-right-radius: 10px; border-bottom-right-radius: 10px;} */
.businessTable tr:nth-child(odd) td {
  background: #FFF;
}

.bListing {
  margin-top: 30px;
  margin-right: -15px;
  padding-right: 15px;
}

/* .business-top .demoLink{ border-radius: 8px !important;} */

.bListing::-webkit-scrollbar {
  width: 9px;
}

.bListing::-webkit-scrollbar-track {
  background: #779FF1;
  border-radius: 20px;
}

.bListing::-webkit-scrollbar-thumb {
  background-color: #00DCCD;
  border-radius: 20px;
}

.roundBox {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #e4ecff !important;
  border-radius: 4px;
  height: 27px;
  margin-left: 5px;
  vertical-align: middle;
  width: 27px;
  cursor: pointer;
  font-size: 18px;
  color: #2552af !important;
}

.tableDrop .dropdown-toggle::after {
  display: none;
}

.tableDrop .dropdown-menu {
  padding: 0 !important;
  left: inherit !important;
  right: 0 !important;
}

.tableDrop .dropdown-menu a {
  display: block;
  width: 100%;
  padding: 8px 10px;
  clear: both;
  font-weight: 400;
  font-size: 13px;
  font-weight: 600;
  border-top: 1px solid #d0deff;
  color: #27499b;
  display: flex;
  align-items: center;
}

.tableDrop .dropdown-menu span {
  display: block;
  width: 100%;
  padding: 8px 10px;
  clear: both;
  font-weight: 400;
  font-size: 13px;
  font-weight: 600;
  border-top: 1px solid #d0deff;
  color: #27499b;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tableDrop .dropdown-menu a svg {
  font-size: 18px;
  margin-right: 5px;
}

.tableDrop .dropdown-menu span svg {
  font-size: 18px;
  margin-right: 5px;
}

.tableDrop .dropdown-menu a:first-child {
  border: none;
}

.tableDrop .dropdown-menu a:hover {
  background: #d0deff;
}

.tableDrop .dropdown-menu span:hover{background: #d0deff;}

.productNav {
  background: #E2E8FF;
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.16);
  box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.16);
}

.productNav .nav-link {
  color: #676767;
  font-size: 17px;
  font-weight: 500;
  padding: 13px 40px;
  position: relative;
  display: flex;
  align-items: center;
}

.productNav .nav-link span {
  font-size: 18px;
  margin-right: 5px;
}

.productNav .nav-link::after {
  content: '';
  width: 1px;
  height: 30px;
  background: #707070;
  position: absolute;
  right: 0;
  top: 8px;
}

.productNav .nav-link.active {
  color: #0053FF;
}

.productNav .nav-item:last-child .nav-link::after {
  opacity: 0;
}

.business-top.alt {
  margin: 30px 0;
}

.navSet {
  background: #0B3C9F;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
}

.navSet .nav-link {
  font-size: 17px;
  font-weight: 500;
  color: #BCD2FF;
  padding: 20px 0;
  position: relative;
}

.navSet .nav-link::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background: #FFF;
}

.navSet .nav-item:last-child .nav-link::after {
  opacity: 0;
}

.navSet .nav-link.active {
  background: #0053FF;
  color: #FFF;
}

.productTabs {
  background: #6784C1;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 30px;
  color: #FFF;
}

.productTabs::-webkit-scrollbar {
  width: 9px;
}

.productTabs::-webkit-scrollbar-track {
  background: #779FF1;
  border-radius: 20px;
}

.productTabs::-webkit-scrollbar-thumb {
  background-color: #00DCCD;
  border-radius: 20px;
}

.inpLabel {
  margin-top: 25px;
}

.inpLabel.line {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 10px;
}

.inpLabel label {
  margin: 0;
  padding: 0 20px 0 0;
  text-align: right;
  white-space: nowrap;
}

.inpLabel-inp {
  background: #FFF;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  width: 100%;
}

input[type=number].inpLabel-inp {
  border-radius: 5px;
}

.inpLabel-inp.square {
  border-radius: 5px;
}

select.inpLabel-inp {
  height: 44px;
}

textarea.inpLabel-inp {
  height: 120px;
  border-radius: 5px;
}

.openHr {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.openHr-left {
  width: 200px;
  padding-right: 20px;
}

.openHr-right {
  background: #496BAE;
  width: 100%;
  padding: 30px;
  border-radius: 20px;
}

.openHr-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.openHr-single:first-child {
  margin-top: 0;
}

.openHr-single-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 280px;
}

.switch.flex {
  display: flex;
  align-items: center;
  width: auto;
}

.switch.flex .slider.round {
  width: 60px;
}

.slClose,
.slOpen {
  padding-left: 70px;
}

.slOpen {
  display: none;
}

input:checked+.slider+.slClose {
  display: none;
}

input:checked+.slider+.slClose+.slOpen {
  display: inline-block;
}

.switch.flex input:checked+.slider:before {
  transform: translateX(28px);
}

.timeFrom {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.timeFrom-box {
  width: 194px;
  height: 40px;
  background: #80A1E5;
  border: 1px solid #FFF;
  border-radius: 5px;
  display: flex;
  padding: 2px;
  margin: 0 0 0 8px;
}

.timeFrom-box select {
  width: 50%;
  background: #80A1E5;
  border: none;
  color: #FFF;
  padding: 0 8px;
}

.timeFrom-box select+select {
  background: #4E7BD6;
  text-align: center;
}

.openHr-single-right {
  display: flex;
  align-items: center;
}

.locateBox {
  display: flex;
  align-items: center;
  height: 44px;
  background: #496BAE;
  border-radius: 5px;
  padding: 0 15px;
  margin-top: 20px;
}

.proThumb {
  width: 70px;
  height: 70px;
  padding: 5px !important;
  border-radius: 5px;
  background: #c8d8ff !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.proThumb img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.btn-sec {
  border-top: 1px solid rgba(255, 255, 255, 1);
  margin-top: 30px;
}

.tableLink {
  color: #002981;
}

.tableLink svg {
  font-size: 20px;
}

.table>:not(caption)>*>* {
  padding: 0;
}

.proType {
  color: #b9b9b9;
  font-weight: 300;
}

.pMode {
  display: inline-block;
  padding: 2px 10px !important;
  border-radius: 5px;
  font-size: 12px;
  min-width: 75px;
  text-align: center;
}

.pMode.sandbox {
  background: red;
  color: #FFF;
}

.pMode.live {
  background: green;
  color: #FFF;
}

.tempList {
  margin-top: 30px;
}

.tempList-single {
  padding: 5px;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  background: var(--blue-color);
}

.tempList ul {
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 20px;
}

.tempList ul li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%;
  max-width: 30%;
  padding-left: 15px;
  padding-right: 15px;
}

.tempList-img {
  height: 350px;
  overflow: hidden;
  border-radius: 5px;
}

.tempList-img img {
  height: 350px;
  width: 100%;
  object-fit: cover;
}

.tempList ul::-webkit-scrollbar {
  height: 9px;
}

.tempList ul::-webkit-scrollbar-track {
  background: #779FF1;
  border-radius: 20px;
}

.tempList ul::-webkit-scrollbar-thumb {
  background-color: #00DCCD;
  border-radius: 20px;
}

.statt-box {
  height: 250px;
  border: 1px solid var(--blue-color);
  border-radius: 10px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFF;
  text-align: center;
}

.statt-box h6 {
  color: #000;
  font-weight: 400;
  padding-bottom: 5px;
}

.statt-box h2 {
  color: var(--blue-color);
}

.statt-box span {
  font-size: 60px;
  color: var(--primary-color);
}

.business-search-left {
  display: flex;
  align-items: center;
}

.businessTable .demoLink {
  padding: 5px 12px !important;
  height: auto !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.demoLink.red {
  background: red !important;
}

.demoLink.red::before {
  display: none;
}

.demoLink.red:hover {
  color: #FFF !important;
}

.inteTop-main {
  padding-top: 0;
  margin-bottom: 80px !important;
}

.designElem {
  background: #FFF;
  padding: 30px;
  border-radius: 20px;
  color: #000;
  display: flex;
  justify-content: space-between;
}

.designElem-left {
  width: 350px;
}

.secCollapse-title {
  font-weight: 600;
  border: 1px solid var(--blue-color);
  color: var(--blue-color);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.secCollapse-title span {
  font-size: 22px;
}

.colorBox {
  border: 1px solid var(--blue-color);
  border-top: 0;
  padding: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.colorBox ul {
  display: flex;
  align-items: center;
  margin: 0 -2px;
  flex-wrap: wrap;
}

.colorBox ul li {
  padding: 0 2px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 14.285%;
  flex: 0 0 14.285%;
  max-width: 14.285%;
  margin-top: 4px;
}

.color-single {
  height: 40px;
  border-radius: 5px;
}

.logoBox {
  border: 1px solid var(--blue-color);
  border-top: 0;
  padding: 15px;
  padding-top: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.logoBox ul {
  display: flex;
  align-items: center;
  margin: 0 -5px;
  flex-wrap: wrap;
}

.logoBox ul li {
  padding: 0 5px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  margin-top: 10px;
}

.logoBox-single {
  height: 70px;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  overflow: hidden;
}

.logoBox-single img {
  width: 100%;
  height: 80px;
  object-fit: cover;
}

.toggleBtn ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--blue-color);
  color: var(--blue-color);
  height: 50px;
  margin-top: 10px;
  padding: 0 20px;
}

.toggleBtn ul li h6 {
  font-size: 1em;
  font-weight: 600;
}

.designElem-right {
  width: calc(100% - 350px);
}

.designPreview {
  max-width: 500px;
  border: 1px solid var(--blue-color);
  padding: 30px;
  border-radius: 20px;
  margin: 0 auto;
}

.prevLogo {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.prevLogo img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.prevName,
.prevDesignation {
  text-align: center;
  font-weight: 600;
  padding-top: 15px;
  color: var(--blue-color);
}

.prevBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.prevBtn span {
  display: inline-block;
  background: var(--blue-color);
  color: #FFF;
  border-radius: 5px;
  padding: 6px 25px;
  margin: 0 5px;
}

.prevBtn button{
  display: inline-block;
  background: var(--blue-color);
  color: #FFF;
  border-radius: 5px;
  padding: 6px 25px;
  margin: 0 5px;
  border: none;
}

.prevAbout {
  margin-top: 15px;
}

.prevAbout h6 {
  color: var(--blue-color);
}

.prevAbout p {
  font-size: 14px;
  color: #000;
  padding-top: 10px;
}

.prevProduct {
  padding-top: 15px;
}

.prevProduct h6 {
  color: var(--blue-color);
}

.prevProduct-single {
  height: 180px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--blue-color);
  margin-top: 15px;
}

.prevProduct-single img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.prevCalendar h6 {
  color: var(--blue-color);
  padding-bottom: 10px;
}

.prevCalendar-wrap .react-calendar {
  width: 100% !important;
  border: 1px solid var(--blue-color) !important;
  border-radius: 5px !important;
}

.prevCalendar {
  padding-top: 15px;
}

.inpLabel label {
  font-size: 13px;
  font-weight: 600;
}

.inpLabel {
  position: relative;
}

.inpLabelWrap {
  margin-top: 15px;
}

.inpLabelWrap .inpLabel-inp {
  height: 70px;
  border-radius: 10px !important;
  padding: 15px 0 0 30px;
  font-size: 14px;
  border: 1px solid #bed1ff;
}

.inpLabelWrap textarea.inpLabel-inp {
  height: 200px;
  padding-top: 35px;
}

.inpLabelWrap .inpLabel-inp:focus {
  border: 1px solid var(--blue-color);
}

.productTabs {
  background: #e4ecff !important;
  color: #003297;
}

.inpLabelWrap .inpLabel label {
  z-index: 1;
  margin: 0;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  text-align: left;
  font-size: 12px;
  font-weight: 700;
  padding: 12px 0 0 30px;
}

.inpLabelWrap .inpLabel {
  margin: 0;
}

.inpLabelWrap span.labelTxt {
  display: block;
  font-size: 13px;
  font-weight: 600;
  padding-bottom: 5px;
}

.openHr-right {
  background: #d3e0ff;
}

.locateBox {
  background: #d3e0ff;
}

.inpLabel.line {
  border-bottom: 1px solid #bcd0ff;
}

.locateBox {
  font-size: 13px;
  font-weight: 600;
}

.locateBox.full{ justify-content: space-between;}
.labelStat{ position: absolute; right: 20px; bottom: 15px; font-weight: 700;}

.openHr {
  font-size: 13px;
  font-weight: 600;
}

.business-list {
  color: var(--blue-color);
}

.openHr {
  display: block;
}

.tablepage ul {
  padding: 20px 0 0;
  text-align: center;
}

.tablepage ul li {
  display: inline-block;
  margin: 0 3px;
  vertical-align: top;
}

.tablepage ul li a {
  align-items: center;
  background: var(--blue-color);
  border-radius: 6px;
  color: #fff;
  display: flex;
  font-size: 16px;
  height: 30px;
  justify-content: center;
  width: 30px;
  font-weight: 600;
}

.tablepage ul li a.active {
  background: #FFF;
  color: var(--blue-color);
}


input[type=number] {
  /*for absolutely positioning spinners*/
  position: relative;
  padding-right: 25px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button !important;
  width: 25px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

/*  */

@media screen and (max-width: 1699px) {

  .hero-banner-left h3,
  .hero-banner-left h2 {
    font-size: 56px;
  }

  .featureSec-right img {
    max-width: 600px;
  }

  .canvas_16X9_height_scroll {
    margin-bottom: 83px;
  }

}

/* Modification */


@media screen and (max-width: 1599px) {
  .hero-banner-left h2 {
    font-size: 50px;
  }

  .hero-banner-left h3 {
    font-size: 50px;
  }

  .hero-banner-left p {
    padding-right: 100px;
  }

  .bannerLink-left img {
    width: 50px;
  }

  .videoProject-top h2 {
    font-size: 28px;
  }

  .proView {
    font-size: 24px;
  }

  /* .videoProject-single-top {
    height: 200px;
  } */

  .videoProject-single-awating,
  .videoProject-single-vid {
    height: 100%;
  }

  .featureSec-single h2 {
    font-size: 28px;
  }

  .featureSec-single h6 {
    font-size: 17px;
  }

  .appHeader-nav>ul>li {
    margin-left: 50px;
  }

  .inteTop-single {
    width: 135px;
  }

  .popularVideo h2 {
    font-size: 25px;
  }

  .tabRight {
    padding: 50px;
  }

  .tabLeft {
    padding: 30px;
  }

  h2.tabTitle {
    font-size: 20px;
  }

  .popular-topics-box h5 {
    font-size: 18px;
  }

  .tabCont .nav-link {
    font-size: 14px;
  }

  .tabepaneIn-main {
    padding: 25px;
  }

  .tabepaneIn-cont h3 {
    font-size: 16px;
  }

  .footer-single ul li a {
    font-size: 13px;
  }

  .footer-single p {
    font-size: 13px;
  }

  .appHeader-nav>ul>li>a {
    font-size: 14px;
  }

  .action-nav button span.profileName {
    font-size: 14px;
  }

  .logo {
    width: 280px;
  }

  .siteWrap {
    padding-top: 70px;
  }

  .vidHover-icon {
    width: 40px;
    height: 40px;
    line-height: 42px;
    font-size: 18px;
  }

  .vidHover-single span+span {
    font-size: 12px;
  }

  .manage-block p {
    font-size: 14px;
  }

  .txtEdit-top {
    flex-wrap: wrap;
  }

  .txtEdit-nav:first-child {
    width: 50%;
  }

  .txtEdit-nav:nth-child(2) {
    width: 50%;
  }

  .txtEdit-nav:nth-child(3) {
    width: 100%;
    padding-left: 0;
    padding-top: 10px;
    margin-top: 10px;
    border-left: 0;
    border-top: 1px solid #707070;
  }

  .course-step-img img {
    height: 50px;
  }

  .course-step ul li a {
    height: 54px;
  }

  .course-step ul li a p {
    font-size: 15px;
  }

  .featureSec-single p {
    font-size: 17px;
  }

  .featureSec-right img {
    max-width: 500px;
  }

  .siteWrap {
    padding-top: 80px;
    min-height: calc(100vh - 80px);
  }

  .editorReel-single {
    height: 60px;
  }

  .editorReel {
    height: 90px;
  }

  .editorReel {
    padding-top: 15px;
  }

  .editor-left {
    height: calc(100vh - 170px);
  }

  .controls {
    padding-top: 0 !important;
  }

  /* .canvas_16X9_editor,
  .canvas_9x16_editor,
  .canvas_1x1_editor {
    transform: scale(0.7);
    transform-origin: top;
  } */
  .canvas_16X9_height_scroll {
    margin-bottom: 80px;
  }

}


@media screen and (max-width: 1360px) {
  .footer-single ul li a {
    font-size: 10px;
  }

  .footer-single ul li {
    margin-left: 6px;
  }

  .footer-single p {
    font-size: 12px;
  }

  .footer-single p a {
    font-size: 12px;
  }

  .hero-banner-left p {
    font-size: 15px;
  }

  .container {
    max-width: 1100px;
  }

  .appHeader-main {
    padding-left: 15px;
    padding-right: 15px;
  }

  .videoProject {
    padding-left: 15px;
    padding-right: 15px;
  }

  .featureSec-left {
    padding-left: 30px;
  }

  .featureSec-single:nth-child(even) .featureSec-left {
    padding-right: 30px;
  }

  .featureSec-single:nth-child(even) .featureSec-right {
    padding-left: 0;
  }

}


@media screen and (max-width: 1199px) {
  .container {
    max-width: 960px;
  }

  .login-container-right {
    padding-left: 0;
  }

  .login-container-right-main {
    padding: 30px;
  }

  .login-container-left h2 {
    font-size: 42px;
  }

  .appHeader-nav>ul>li {
    margin-left: 25px;
  }

  .logo {
    width: 180px;
  }

  .siteWrap {
    padding-top: 70px;
  }

  .hero-banner-right {
    padding-left: 30px;
  }

  .hero-banner-left h2,
  .hero-banner-left h3 {
    font-size: 40px;
  }

  .bannerLink {
    margin: 15px 0;
    height: 66px;
  }

  .hero-banner {
    height: 480px;
  }

  .videoProject {
    padding: 50px 0;
  }

  /* .featureSec-left {
    padding: 0 !important;
  } */

  .featureSec {
    padding-top: 50px;
  }

  .lessonLink-right {
    padding-left: 0;
  }

  .featureSec-single h2 {
    font-size: 24px;
  }

  .featureSec-single h6 {
    font-size: 15px;
  }

  .inteTop-main ul li {
    margin-bottom: 10px;
  }

  .help-banner {
    height: 330px;
  }

  .row.d-flex.justify-content-center.mt-5 {
    margin-top: 0 !important;
  }

  .row.d-flex.justify-content-center.mt-5 .col-xl-6 {
    margin-top: 10px !important;
  }

  /* .cont-research-head h2 {
    font-size: 20px;
  } */

  .editor-left {
    width: 350px;
    padding: 15px;
  }

  .editor-right {
    padding-left: 370px;
  }

  .story-block-single {
    padding: 10px;
  }

  .campaignBar .demoLink {
    font-size: 13px;
  }

  .campaignBar {
    padding: 15px;
  }

  .campaignNav {
    padding-left: 15px;
    padding-right: 15px;
  }

  .campaignNav .nav-link {
    width: 72px;
    margin: 5px 2px;
  }

  .campaignNav .nav-link span+span {
    font-size: 11px;
  }

  .roundInp {
    font-size: 13px;
  }

  .canvasEditor {
    max-width: 550px;
  }

  .hero-banner-left {
    padding-right: 0;
  }

  .lessonLink-right h2 {
    font-size: 25px;
  }

  .lessonLink-right p {
    font-size: 14px;
  }

  .lessonLink-right h6 {
    font-size: 18px;
  }

  .projectTitle-left h2 {
    font-size: 15px;
  }

  .projectTitle-left h2 span {
    font-size: 13px;
  }

  .contWrap-title h2 {
    font-size: 16px;
  }

  .contWrap-title .demoLink {
    font-size: 12px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media screen and (max-width: 1023px) {
  /* .container {
    max-width: 700px;
  } */

  .full-height-row,
  .login-container {
    min-height: 10px;
  }

  .full-height-container {
    min-height: calc(100vh - 130px);
  }

  .login-container {
    padding-top: 130px;
  }

  .footer-single ul {
    text-align: center;
  }

  .footer-single.reelLogo {
    text-align: center;
  }

  .login-container-left {
    padding-bottom: 50px;
  }

  .footer-single p {
    padding: 10px 0;
  }

  .hero-banner {
    height: auto;
    /* padding: 50px 0; */
  }

  .hero-banner-txt {
    position: static;
  }

  .hero-banner-right {
    padding-left: 0;
  }

  .hero-banner-left {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .appHeader-right {
    position: fixed;
    left: -300px;
    top: 0;
    width: 300px;
    background: #000;
    height: 100vh;
    transition: all 0.5s ease;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .appHeader-right.act {
    left: 0;
  }

  .appHeader-nav>ul>li {
    display: block;
    margin-left: 0 !important;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
  }

  .appHeader-nav>ul>li>a {
    display: block;
    padding: 15px 15px;
    font-size: 14px;
  }

  .appHeader-action {
    display: block;
    padding: 15px;
  }

  .notification-nav {
    margin-left: 0;
  }

  .action-nav {
    margin-left: 0;
    margin-top: 15px;
  }

  .notification-nav button {
    width: 100%;
  }

  .action-nav .dropdown {
    display: block;
  }

  .action-nav .dropdown-menu {
    position: static !important;
    width: 100%;
    margin: 0 !important;
    transform: none !important;
  }

  .notification-nav .dropdown-menu {
    position: static !important;
    width: 100%;
    margin: 0 !important;
    transform: none !important;
  }

  .action-nav button {
    width: 100% !important;
    border: 1px solid rgba(255, 255, 255, .1);
    border-radius: 50px;
    padding: 5px 5px;
  }

  .action-nav button::after {
    right: 15px;
  }

  .featureSec-single [class*='col-'] {
    order: 2;
  }

  .featureSec-single [class*='col-']+[class*='col-'] {
    order: 1;
  }

  .featureSec-left {
    padding-top: 30px !important;
  }

  .navExpand {
    display: block;
    color: #FFF;
    font-size: 23px;
  }

  .projectTitle {
    display: block;
  }

  .projectTitle-right {
    justify-content: flex-start;
    padding-top: 15px;
  }

  .projectTitle-left h2 {
    font-size: 20px;
  }

  .projectTitle-left h2 span {
    font-size: 15px;
  }

  .tabLeft {
    min-height: 10px;
  }

  .manage-block {
    display: block;
  }

  .manage-block .btn {
    margin: 15px 0 0 0 !important;
  }

  .research-body-right {
    padding: 40px 0;
  }

  .editor-left {
    width: 100%;
    position: static;
    height: auto;
  }

  .editor-right {
    padding-left: 0;
  }

  .hero-banner-right {
    position: relative;
    z-index: 8;
  }

  .hero-banner-left {
    position: relative;
    z-index: 8;
  }

  .featureSec-right {
    text-align: center !important;
    margin: 0 !important;
  }

  .canvas_16X9_editor,
  .canvas_9x16_editor,
  .canvas_1x1_editor {
    transform: scale(0.7);
    transform-origin: top;
  }

  .contWrap-title {
    display: block;
  }

  .contWrap-title a+div {
    padding-top: 15px;
    text-align: center;
    border-top: 1px solid #626262;
    margin-top: 15px;
  }

}

@media screen and (max-width: 767px) {
  .appFooter {
    position: static;
  }

  .loginLogo {
    width: 180px;
  }

  .login-container {
    padding-bottom: 50px;
  }

  .login-container-right-main h2 {
    font-size: 19px;
  }

  .login-container {
    padding-top: 90px;
  }

  .login-container-left h2 {
    font-size: 29px;
  }

  .login-container-left p {
    font-size: 14px;
  }

  .footer-single ul li a {
    font-size: 13px;
  }

  .footer-single p {
    font-size: 13px;
    padding: 3px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.02);
    border-bottom: 1px solid rgba(255, 255, 255, 0.02);
  }

  .loginHeader {
    padding-top: 15px;
  }

  .appFooter {
    padding: 5px 0;
  }

  .footer-single.reelLogo img {
    width: 100px;
  }

  .login-container-right-main {
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
  }

  .hero-banner-left h2,
  .hero-banner-left h3 {
    font-size: 26px;
  }

  .hero-banner-left p {
    padding-right: 0;
    font-size: 12px;
  }

  .hero-banner {
    padding-top: 0;
  }

  .bannerLink {
    font-size: 13px;
  }

  .videoProject-top h2 {
    font-size: 17px;
  }

  .proView {
    font-size: 15px;
  }

  .proView span {
    width: 25px;
    height: 25px;
    font-size: 16px;
    margin-left: 5px;
  }

  .videoProject-bottom {
    padding-top: 0;
  }

  .featureSec-single h2 {
    font-size: 18px;
  }

  .featureSec-single h6 {
    font-size: 12px;
  }

  .featureSec-single p {
    font-size: 13px;
  }

  .textL {
    font-size: 13px;
  }

  .lessonLink-right p {
    font-size: 13px;
  }

  .lessonLink-right h6 {
    font-size: 15px;
  }

  .lessonLink-right {
    padding-top: 30px;
  }

  .demoLink {
    font-size: 13px;
  }

  .hero-banner-left {
    padding-bottom: 15px;
  }

  .lessonLink {
    padding: 30px 0;
  }

  .projectTitle-right {
    display: block;
  }

  .projectTitle-right .demoLink {
    width: 100%;
    margin-top: 8px;
  }

  .project-input-box .input-group input {
    height: 38px;
  }

  .forproject .row.mt-5.mb-5 {
    margin-top: 15px !important;
  }

  .inteTop-main ul li {
    margin-left: 5px;
    margin-right: 5px;
  }

  h2.titleBdr {
    font-size: 22px;
  }

  .connection-single {
    display: block;
    height: auto;
    padding: 15px;
  }

  .connectBt {
    width: 100%;
    margin: 8px 0 0 0;
    font-size: 13px;
  }

  .single-left-img img {
    width: 40px;
  }

  .connection-single-left p {
    font-size: 12px;
    padding-left: 10px;
  }

  .inteTop-single img {
    max-height: 42px;
  }

  .inteTop-single {
    height: 120px;
  }

  .help-banner h2 {
    font-size: 21px;
  }

  .impTopic {
    padding: 50px 0;
  }

  .impTopic h2 {
    font-size: 20px;
  }

  .impTopic-single img {
    width: 120px;
  }

  .impTopic-single {
    height: 200px;
    margin-top: 15px;
  }

  .impTopic-single h3 {
    font-size: 15px;
  }

  .popularVideo h2 {
    font-size: 15px;
  }

  .popularVideo-single {
    margin-top: 15px;
  }

  .popularVideo {
    padding: 50px 0;
  }

  .helpDesk {
    padding: 40px 0;
  }

  .helpDesk-right {
    padding-top: 40px;
  }

  .helpDesk h6 {
    font-size: 14px;
  }

  .helpDesk p {
    font-size: 13px;
  }

  .helpSearch input[type="text"] {
    width: 100%;
  }

  .tabRight {
    padding: 30px;
  }

  .tabepaneIn-main {
    padding: 5px;
    border-radius: 10px;
  }

  .tabepaneIn-cont {
    padding: 10px;
    padding-top: 0;
  }

  .tabepaneIn-cont h3 {
    font-size: 14px;
  }

  .tabCont .nav-link {
    padding: 10px 15px !important;
    font-size: 12px;
  }

  .profileBlock {
    padding: 15px;
    padding-top: 20px;
  }

  .siteWrap-in {
    padding: 50px 0;
  }

  .proImage h5 {
    font-size: 17px;
  }

  .form-group-alt .form-control {
    font-size: 13px;
  }

  .manage-block .btn {
    font-size: 13px;
  }

  .manage-block {
    padding: 15px;
  }

  .footer-single ul li {
    margin-left: 20px;
  }

  .manage-block p {
    font-size: 13px;
  }

  .infoTitle {
    font-size: 16px;
  }

  .tabepaneIn-cont p {
    font-size: 12px;
  }

  .cont-research-head {
    display: block;
  }

  /* .cont-research-head h2 {
    font-size: 17px;
  } */

  .research-head-right {
    display: block;
    padding-top: 10px;
  }

  .roundInp {
    width: 100%;
  }

  .research-block-top {
    display: block;
  }

  .research-block-top-right,
  .research-block-top-left {
    width: 100%;
  }

  .research-block.cont-scroll {
    height: 50vh;
  }

  .research-block {
    padding: 10px;
    border-radius: 10px;
  }

  .txtEdit-nav:first-child {
    width: 100%;
  }

  .txtEdit-nav:nth-child(2) {
    width: 100%;
    padding-left: 0;
    padding-top: 10px;
    margin-top: 10px;
    border-left: 0;
    border-top: 1px solid #707070;
  }

  .course-step ul li {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 10px;
  }

  .course-step ul li:first-child {
    margin-top: 0;
  }

  .create-course-mid {
    padding: 50px 0;
  }

  .create-course-top h2 {
    font-size: 20px;
  }

  .campaignBar {
    display: block;
  }

  .campaignBar .demoLink {
    width: 100%;
    margin: 5px 0 0 0;
  }

  .editorReel {
    padding-left: 10px;
    padding-right: 10px;
  }

  .canvasEditor {
    padding-left: 15px;
    padding-right: 15px;
  }

  .campaignNav .nav-item {
    width: 50%;
    padding: 2px;
  }

  .campaignNav .nav-link {
    width: 100%;
    height: 50px;
    margin: 0px 2px;
  }

  .campaignTop {
    position: absolute;
    width: 100%;
    left: 0;
    top: 70px;
  }

  .editorWrap {
    padding-top: 384px;
  }

  .videoControll {
    padding-left: 10px;
    padding-right: 10px;
  }

  .videoControll-txt span {
    font-size: 10px;
  }

  .videoControll-txt span+span {
    margin-left: 10px;
  }

  .videoControll-nav ul li {
    font-size: 15px;
  }

  .videoControll-right {
    font-size: 15px;
    transform: translateY(6px);
  }

  .videoControll-nav ul {
    transform: translateY(6px);
  }

  .inTabNav.nav .nav-link {
    font-size: 11px;
  }

  .motionlist-single {
    font-size: 11px;
  }

  .transition-txt {
    font-size: 11px;
  }

  .featureSec-right img {
    max-width: 100%;
  }

  .appHeader {
    z-index: 99;
  }

  .featureSec-right {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .featureSec-left {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .appHeader-main {
    padding-left: 0;
    padding-right: 0;
  }

  .featureSec {
    padding-top: 0;
  }

  .merge-btn {
    width: 100%;
    margin: 0;
  }

  .project-input-box {
    margin-top: 8px;
  }

  .connection-img {
    width: 30px;
  }

  .connection-box {
    padding: 12px;
  }

  .connection-txt {
    width: calc(100% - 40px);
  }

  .facebook-connect-account button,
  .facebook-delete button {
    font-size: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .noConnect h6 {
    font-size: 13px;
  }

  .noConnect p {
    font-size: 13px;
  }

  .facebook-content h6 {
    font-size: 13px;
  }

  .facebook-content p {
    font-size: 13px;
  }

  .facebook-connection {
    margin-top: 0;
  }

  .campaign-name-mid {
    padding: 15px;
    margin: 15px 0;
  }
}


/* CSS Modified ================================================================================================ */

.text-white p span {
  color: #ffffff !important;
}

/* .disable-img img {
  filter: grayscale(100%);
} */

.logo-image-css {
  /* height: 40px;
  width: 210px;
  object-fit: contain; */
  width: 100%;
  overflow: hidden;
}

/* ------------EDITOR LOADER --------- */

.loader {
  width: 80px;
  height: 80px;
  border: 2px var(--secondary-btn-color) solid;
  border-radius: 50%;
  animation: rotation 1s ease-in-out infinite;
  text-align: center;
}

.loader-sec {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader::after {
  width: 10px;
  height: 10px;
  background-color: var(--secondary-btn-color);
  border-radius: 100%;
  position: absolute;
  content: "";
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.crop-icon {
  position: absolute;
  top: 9px;
  left: 10px;
  width: auto !important;
  height: auto !important;
  z-index: 2;
  color: #4F94C9;
  cursor: pointer;
}

.slide-active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #AC46B4;
}

.slide-active i {
  color: #AC46B4;
  transform: scale(1.5);
}

.active-audio .active-audio-wrapper i {
  color: #fff;
}

.active-audio .active-audio-wrapper {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000b3;
  z-index: 1;
  border-radius: 7px;
  border: 2px solid
}

.active-audio-wrapper {
  display: none;
}

.color-picker-style {
  /* color: white; */
  position: relative;
  width: 80% !important;
  padding: 10px 10px 0px;
  box-sizing: initial;
  background: rgb(112 2 247);
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px;
  margin: 0 auto;
}

.font-active {
  border: 1px solid #E1A6FF;
  color: #fff;
}

.flexbox-fix {
  justify-content: center !important;
}

.flexbox-fix label {
  font-size: 12px;
  font-weight: 600;
  color: #000;
}

.sketch-picker-wrapper {
  background: #303030;
  border-radius: 10px;
  padding: 0 0 20px 0;
  width: 100%;
}

.sketch-picker-head h4 {
  color: #fff;
  padding: 10px 0;
  margin-left: 28px;
  font-size: 15px;
}

.sketch-picker.color-picker-style .flexbox-fix:last-child div {
  width: 50px !important;
  height: 40px !important;
}

.selected-tile {
  border: 2px solid #e1a6ff;
  border-radius: 10px;
}

.editorReel-single .delIcon {
  position: absolute;
  right: 3px;
  top: 4px;
  color: '#fff';
  font-size: 20px;
}

.voiceIcn-off {
  position: absolute;
  left: 0;
  top: 3px;
  font-size: 20px;
  color: #fff;
}

.voiceIcn {
  position: absolute;
  left: 0;
  top: 3px;
  color: #fff;
  font-size: 25px;
}

.translate-btn {
  display: inline-block;
  color: #FFF;
  font-weight: 600;
  padding: 5px 14px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
  background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
}

.btn-custom-disable {
  padding: 8px 20px;
  border-radius: 5px;
  color: #fff;
}

li::marker {
  font-size: 0 !important;
}


.add-block-main .inPut {
  width: 100%;
  background: #0C0C0C;
  color: #FFF;
  border-radius: 4px;
  border: 1px solid #707070;
  font-size: 13px;
  padding: 8px 5px;
}

.slidern {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #171717;
  outline: none;
  border-radius: 10px;
}

.slidern::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #AC46B4;
  cursor: pointer;
  border-radius: 100%;
}

.slidern::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #AC46B4;
  cursor: pointer;
}

.yotube-btn {
  padding: 8px 26px;
}

.translate-btn,
.yotube-btn {
  background: linear-gradient(to right, #AC46B4 0%, #4F94C9 100%);
  border: none;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  transition: all .5s ease;
  position: relative;
  z-index: 1;
}

.translate-btn::before,
.yotube-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 0%;
  height: 100%;
  z-index: -1;
  transition: .3s ease-in-out;
  border-radius: 50px;
}

.translate-btn:hover,
.yotube-btn:hover {
  color: var(--btn-hover-color) !important;
  transition: .3s ease-in-out;
}

.translate-btn:hover:before,
.yotube-btn:hover:before {
  width: 100%;
  color: #fff !important;
  transition: .3s ease-in-out;
}

.video-card-wrapper {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  cursor: pointer;
  overflow: hidden;
  background-color: #d8d8d8;
}

.video-card-wrapper-1 {
  width: 100%;
  max-width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  background: linear-gradient(190deg, #E1A6FF 0%, var(--secondary-btn-color) 100%);

}

.dashboard-card-bg {
  background: #646464;
}

.video-card-wrapper-2 {
  width: 100%;
  max-width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: linear-gradient(180deg, #d75348 0, #a2000f 50%);
}

.videoProject-single-icon {
  display: flex;
  align-items: center;
}

.single-icon-txt {
  font-size: 13px;
}

.single-icon-icon {
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 100%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue-color);
}

.video-card-content {
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.video-card-content-2 {
  height: 100%;
  width: 100%;
}

.video-card-content p {
  color: #747474;
  font-size: 18px;
  font-weight: 600;
  margin-top: -4px;
  font-weight: 400 !important;
  color: #BBBBBB !important;
}

.vidThumb-img {
  /* width: 60px;
  filter: brightness(8.5); */

}

.vidThumb-img-1 {
  filter: grayscale(100%) !important;

}

.card-icon-delete {
  position: absolute;
  top: 3px;
  cursor: pointer;
  color: #fff;
  left: 5px;
}


/* EDITOR PREVIEW CSS  */

.loader-center-temp1 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary-btn-color);
  height: 100%;
}

.layer-border {
  border: 2px dashed #4F94C9;
  padding: 3px;
}

.logo-style {
  z-index: 2;
  max-width: 100px;
}

.watermark-op {
  opacity: .5;
}

.logo-top-left {
  position: absolute !important;
  top: 0;
  left: 0;
  padding: 10px;
}

.logo-top-right {
  position: absolute !important;
  top: 0;
  right: 0;
  padding: 10px;
}

.logo-bottom-right {
  position: absolute !important;
  bottom: 0;
  right: 0;
  padding: 10px;
}

.logo-bottom-left {
  position: absolute !important;
  bottom: 0;
  left: 0;
  padding: 10px;
}

.logo-top-center {
  position: absolute !important;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
}

.logo-center-left {
  position: absolute !important;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding: 10px;
}

.logo-center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}

.logo-center-right {
  position: absolute !important;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 10px;
}

.logo-bottom-center {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
}

.remove-media {
  position: absolute;
  top: 6px;
  right: 20px;
  z-index: 2;
  font-size: 20px;
  cursor: pointer;
}

.remove-media:hover {
  color: #e512c9;
}

.editor-background-img {
  height: 100% !important;
}

.disable-button {
  background: linear-gradient(gray, gray);
}

.transition-wrapper {
  display: flex;
  align-items: center;
}

.controls {
  padding: 10px 0;
  /* background:#000;
  margin-bottom: 20px; */
  border-radius: 15px;

}

.voice-record-time span {
  font-size: 65px;
}

.record-img {
  width: 50px;
  height: 50px;
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.recording-wrapper li {
  margin-bottom: 10px;
  padding-right: 0;
}

.this-hover:hover {
  color: purple !important;
  transition: none;
}

/* REEL MERGE */

.editor-main {
  height: 100vh;
}

.icon-response li {
  width: 120px;
  height: 120px;
  margin: 0 10px;
  text-align: center;
  padding: 20px;
  display: block;
  color: #fff;
  border-radius: 50%;
}

.icon-response .disable {
  background: none !important;
}

.icon-response li:hover {
  width: 120px;
  height: 120px;
  background-color: gray !important;
  text-align: center;
  box-shadow: 0 0 20px 0rgb(0 0 0/40%), 0 0 20px 0rgb(0 0 0/40%);
  border-radius: 50%;


}

/* publish select on click class name */

.icon-response li.select-social {
  width: 120px;
  height: 120px;
  background: linear-gradient(to right, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  text-align: center;
  box-shadow: 0 0 20px 0rgb(0 0 0/40%), 0 0 20px 0rgb(0 0 0/40%);
  border-radius: 50%;


}

.icon-response li a {
  color: #fff;
}

.icon-response li p {
  color: #fff;
  font-size: 13px;
}




.icon-response .img-wrraper {
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-response-span:hover .wrap-icon-image {
  width: 80px;
  height: 80px;
  /* background: red; */
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 0px 5px;
  transition: .4s ease-in-out;
}


.icon-response-span .wrap-icon-image {
  width: 80px;
  height: 80px;
  /* background: red; */
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: .4s ease-in-out;
}


.dots-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;

}

.loading__dot {
  animation: dot ease-in-out 1s infinite;
  background-color: grey;
  display: inline-block;
  height: 4px;
  margin-top: 8px;
  margin-right: 4px;
  width: 5px;
  border-radius: 50%;
}

.loading__dot:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loading__dot:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes dot {
  0% {
    background-color: grey;
    transform: scale(1);
  }

  50% {
    background-color: #e512c9;
    transform: scale(1.3);
  }

  100% {
    background-color: grey;
    transform: scale(1);
  }

}

.create-course-mid .btn-success {
  /* background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%)!important; */
  background-color: #e512c9;
}

.cour-edit {
  color: #CE88FF;
  background: transparent;
  border: none;
  font-size: 17px;
  font-weight: 600;
}

.merge-delete {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  right: 4px;
  top: 3px;
  font-size: 15px;
}

/* reel-merge-tiles */

.slide-sec-merge {
  background-color: #000000;
  padding: 15px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9;
}

.slide-box {
  overflow-x: auto !important;
  overflow-y: hidden;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.slide-box ul li {
  overflow: hidden;
}

.slide-box::-webkit-scrollbar {
  height: 5px;

}

.slide-box::-webkit-scrollbar-thumb {
  background-color: var(--btn-hover-color);
  width: 8px;
  border-radius: 10px;
}

.slide-box::-webkit-scrollbar-track {
  background-color: #5555;
}

.slide-box::-webkit-scrollbar-track:hover {
  background-color: #555;
}

.merge-btn {
  padding: 10px 20px;
  border-radius: 10px;
  color: #000;
  border: none;
  font-size: 14px;
  font-family: var(--poppins);
  font-weight: 500;
  margin-right: 10px;
}

.merge-btn option {
  background-color: #fff;
  color: #000;
  border-radius: 10px !important;
}

.publish-discription {
  padding-top: 35px !important;
}

.publish-discription::placeholder {
  font-size: 13px;

}

.logo-image-set {
  height: 50px !important;
  width: 230px !important;
  /* object-fit: cover */
}

.render-overlay {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.alert-container {
  display: flex;
  align-items: right;
  justify-content: right;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.alert-class {
  background: rgba(0, 0, 0, 0.9);
  max-width: 70%;
  min-height: 50px;
  position: fixed;
  z-index: 9999999;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  padding: 8px;
  font-family: 'Open Sans', sans-serif;
}

.alert-text-style {
  margin-left: 20px;
  font-size: 17px;
}

.alert-icon-style {
  font-size: 25px;
}

.remove_line {
  background: red;
  height: 64px;
  left: 22px;
  position: absolute;
  top: -24px;
  transform: rotate(45deg);
  width: 2px;
}

.media-loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0091c8;
  font-size: 25px;
}

.no-showvideotag {
  visibility: hidden;
  height: 0px;
  width: 0px;
}



.infinite-scroll-component {
  -ms-overflow-style: none;
  /* Edge, Internet Explorer */
  scrollbar-width: none;
  /* Firefox */
  overflow-x: hidden;
  overflow-y: scroll;
}

.infinite-scroll-component::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera */
}

.mediaList .fa-spin {
  color: #e1a6ff;
}

.video-set-delete-icon {
  position: absolute;
  top: 0px;
  right: 5px;
  font-size: 23px;
  color: #e1a6ff;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.delete-icon-media {
  position: absolute;
  top: 3px;
  right: 2px;
  color: #e1a6ff;
}

.contWrap-list-single:hover .video-set-delete-icon {
  opacity: 1;
  visibility: visible;
}

.videoProject-single-icon {
  width: 100%;
}

.single-icon-txt input {
  width: 100%;
  background: none;
  border: 1px solid #707070;
  color: #FFF;
  padding: 8px 10px;
  border-radius: 50px;
}

.videoProject-single-edName input {
  width: 100%;
  background: none;
  border: none;
  color: #FFF;
  padding: 3px 10px;
  border-radius: 4px;
}

.back-text-css {
  color: crimson
}

.linear-border {
  border: 2px solid transparent;
  border-radius: 8px;
  background: linear-gradient(#0e0e0e, #080808) padding-box, linear-gradient(225deg, var(--primary-btn-color) 50%, var(--secondary-btn-color) 50%);

}

.box-decoraion-css {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.css-for-svgs {
  height: 100% !important;
  width: 100% !important;
  padding: 2px;
}

.swal2-shown {
  padding-right: 0 !important;
}

.copy-button {
  background: linear-gradient(to right, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%);
  border: none;
  border-radius: 5px;
  color: #fff;
  float: right;
  font-weight: 600;
  margin-top: 5px;
  padding: 7px 11px;
}